import { Box, Card, styled, Typography } from "@mui/material";
import { IconButton } from "@mui/material";

export const StyledGalleryContainer = styled(Box)(({ theme }) => ({
    // position: 'relative', 
    width: '100%', 
    backgroundColor: theme.palette.secondary.main,
    paddingTop: '2%',
    paddingBottom: '2%',
    height: '70vh'
}))

export const StyledGalleryWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
});

export const StyledLeftArrowIconButton = styled(IconButton)({
    color: 'white',
    marginLeft: '1%'
});

export const StyledRightArrowIconButton = styled(IconButton)({
    color: 'white',
    marginRight: '1%'
});

export const StyledCardsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowX: 'auto',
    marginLeft: '5%',
    marginRight: '5%',
    overflow: 'hidden',
})

export const StyledCard = styled(Card)({
    flexShrink: 0,
    width: '600px',
    margin: '0 10px',
    borderRadius: 2,
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': { boxShadow: 4 },
    cursor: 'pointer',
})

export const StyledCardText = styled(Typography)(({ theme }) => ({
    padding: 1,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'center'
}))