import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AvailableViews from "../../enums/views";
import MessageDisplay from "../general/MessageDisplay";
import HomePage from "../homepage/HomePage";
import PrivateEventsPage from "../private_events/PrivateEventsPage";
import PublicEventsPage from "../public_events/PublicEventsPage";
import ContactFormsPage from "../contact_forms/ContactFormsPage";

const MainRouter: React.FC = () => {
    
    return(
        <BrowserRouter>
            <Routes>
                <Route path={AvailableViews.Main} element={<HomePage />} />
                <Route path={AvailableViews.Test} element={<MessageDisplay />} />
                <Route path={AvailableViews.PrivateEvents} element={<PrivateEventsPage />} />
                <Route path={AvailableViews.PublicEvents} element={<PublicEventsPage />} />
                <Route path={AvailableViews.ContactForms} element={<ContactFormsPage isOpen={false} />} />
                <Route path={AvailableViews.ContactFormWithFormOpen} element={<ContactFormsPage isOpen={true} />} />
            </Routes>
        </BrowserRouter>
    )
}

export default MainRouter