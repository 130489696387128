import React from 'react'
import { ScrollingTexts } from '../../../enums/page_texts/homepage_texts'
import { ScrollingTextContainer, ScrollText, Row } from '../../../styles/Homepage/ScrollingTexts.style'

const ScrollingText: React.FC = () => {
    return (
        <ScrollingTextContainer>
            <Row>
                <ScrollText variant="h4" direction="left">
                    <a href='/private-events' style={{ all: 'unset' }}>
                        {ScrollingTexts.Row1Text1} · {ScrollingTexts.Row1Text2} · {ScrollingTexts.Row1Text3}
                    </a>
                </ScrollText>
            </Row>
            <Row>
                <ScrollText variant="h4" direction="right">
                    <a href='/public-events' style={{ all: 'unset' }}>
                        {ScrollingTexts.Row2Text1} · {ScrollingTexts.Row2Text2} · {ScrollingTexts.Row2Text3}
                    </a>
                </ScrollText>
            </Row>
        </ScrollingTextContainer>
    )
}

export default ScrollingText