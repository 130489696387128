enum AvailableViews {
    Main = '/',
    Test = '/test',
    PrivateEvents = '/private-events',
    PublicEvents = '/public-events',
    ContactForms = '/contact-forms',
    ContactFormWithFormOpen = '/contact-form-open'

}

export default AvailableViews