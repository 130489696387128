import React from 'react';
import { Divider } from '@mui/material';
import { StepFormContainer, StyledQuestionText } from '../../styles/Forms/StepsForm.style';

interface Step6FormProps {
  formData: {
    eventType: string;
    isPrivateEvent: boolean;
    materialList: string[];
    venueType: string;
    musicStylesList: string[];
    guestsAge: string;
    budget: string;
    guestsAmount: string;
    venueAddress: string;
    eventDate: string;
    isDateFlexible: boolean | string;
    startHour: string;
    endHour: string;
    otherInformation: string;
    wantsArtis: boolean | string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    tvaNumber?: string;
    isMaterialInVenue?: string;
    otherVenueType?: string;
  };
}

const Step6Form: React.FC<Step6FormProps> = ({ formData }) => {
  return (
    <StepFormContainer>
      <StyledQuestionText variant="h6" sx={{color:'white'}}>
        Il ne reste plus qu'à valider votre demande de devis !
      </StyledQuestionText>
      <StyledQuestionText variant="h6" gutterBottom sx={{color:'white'}}>
        Voici un récapitulatif de vos informations.
      </StyledQuestionText>
      <Divider sx={{ my: 2 }} />
      
      <StyledQuestionText>
        <strong>Type d'événement :</strong> {formData.eventType}
      </StyledQuestionText>
      {formData.isPrivateEvent !== undefined && (
        <StyledQuestionText>
          <strong>Événement privé :</strong> {formData.isPrivateEvent ? 'Oui' : 'Non'}
        </StyledQuestionText>
      )}
      {formData.materialList.length > 0 && (
        <StyledQuestionText>
          <strong>Matériel :</strong> {formData.materialList.join(', ')}
        </StyledQuestionText>
      )}
      {formData.isMaterialInVenue && (
        <StyledQuestionText>
          <strong>Matériel sur place :</strong> {formData.isMaterialInVenue}
        </StyledQuestionText>
      )}
      <StyledQuestionText>
        <strong>Type de salle :</strong> {formData.venueType}
      </StyledQuestionText>
      {formData.otherVenueType && (
        <StyledQuestionText>
          <strong>Autre type de salle :</strong> {formData.otherVenueType}
        </StyledQuestionText>
      )}
      <StyledQuestionText>
        <strong>Styles musicaux :</strong> {formData.musicStylesList.join(', ')}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Âge des invités :</strong> {formData.guestsAge}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Budget :</strong> {formData.budget || 'Non précisé'}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Nombre d'invités :</strong> {formData.guestsAmount || 'Non précisé'}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Adresse du lieu :</strong> {formData.venueAddress}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Date de l'événement :</strong> {formData.eventDate}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Date flexible :</strong> {formData.isDateFlexible ? 'Oui' : 'Non'}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Heure de début :</strong> {formData.startHour}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Heure de fin :</strong> {formData.endHour}
      </StyledQuestionText>
      {formData.otherInformation && (
        <StyledQuestionText>
          <strong>Autres informations :</strong> {formData.otherInformation}
        </StyledQuestionText>
      )}
      <StyledQuestionText>
        <strong>Artis souhaité :</strong> {formData.wantsArtis ? 'Oui' : 'Non'}
      </StyledQuestionText>
      <Divider sx={{ my: 2 }} />
      <StyledQuestionText>
        <strong>Prénom :</strong> {formData.firstName}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Nom :</strong> {formData.lastName}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Email :</strong> {formData.email}
      </StyledQuestionText>
      <StyledQuestionText>
        <strong>Téléphone :</strong> {formData.phoneNumber}
      </StyledQuestionText>
      {formData.tvaNumber && (
        <StyledQuestionText>
          <strong>Numéro TVA :</strong> {formData.tvaNumber}
        </StyledQuestionText>
      )}
    </StepFormContainer>
  );
};

export default Step6Form;
