import { Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const scrollRightToLeft = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const scrollLeftToRight = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`;

export const ScrollingTextContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent',
  padding: theme.spacing(2),
  position: 'relative',
}));

export const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  width: '100%',
});

export const ScrollText = styled(Typography)<{ direction: "left" | "right" }>(({ theme, direction }) => ({
  display: 'inline-block',
  textTransform: 'uppercase',
  color: 'transparent',
  WebkitTextStroke: `2px ${theme.palette.primary.main}`,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  minWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    animation: `${direction === 'left' ? scrollRightToLeft : scrollLeftToRight} 10s linear infinite`,
    paddingRight: '150%',
  },
  [theme.breakpoints.up('lg')]: {
    animation: `${direction === 'left' ? scrollRightToLeft : scrollLeftToRight} 10s linear infinite`,
    paddingRight: '65%',
    fontSize: '8rem',
  },
  '&:hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
}));