import React, { useState } from 'react'
import { ErrorText, StepFormContainer, StyledCheckbox, StyledFormControlLabel, StyledQuestionText, StyledTextField } from '../../styles/Forms/StepsForm.style';
import { FormGroup, MenuItem, TextField } from '@mui/material';
import { EventTypes, MaterialOptions, Step1FormQuestions, VenueTypes, VenueTypesForFestival } from '../../enums/page_texts/contact_forms_texts';

interface Step1FormProps {
    formData: {
        eventType: string;
        otherEventType?: string;
        isPrivateEvent: boolean;
        materialList: string[];
        otherMaterial?: string[];
        isMaterialInVenue?: string;
        venueType: string;
        otherVenueType?: string;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleMaterialListChange: (material: string) => void;
    errors: {
        eventType?: string;
        otherEventType?: string;
        isMaterialInVenue?: string;
        materialList?: string;
        otherMaterial?: string;
        venueType?: string;
        otherVenueType?: string;
    };
}

const Step1Form: React.FC<Step1FormProps> = ({ formData, handleChange, handleMaterialListChange, errors }) => {
    const [otherEventType, setOtherEventType] = useState(formData.otherEventType || '')
    const [otherVenueType, setOtherVenueType] = useState(formData.otherVenueType || '')
    const [otherMaterial, setOtherMaterial] = useState(formData.otherMaterial || '')

    const handleOtherEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherEventType(event.target.value);
        handleChange({
            ...event,
            target: {
                ...event.target,
                name: 'otherEventType',
                value: event.target.value,
            },
        })
    }

    const handleOtherVenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherVenueType(event.target.value);
        handleChange({
            ...event,
            target: {
                ...event.target,
                name: 'otherVenueType',
                value: event.target.value,
            },
        })
    }

    const handleOtherMaterialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherMaterial(event.target.value);
        handleChange({
            ...event,
            target: {
                ...event.target,
                name: 'otherMaterial',
                value: event.target.value,
            },
        })
    }

    return (
        <StepFormContainer>
            {/* De quel type d'événement s'agit-il? */}
            <StyledQuestionText>
                {Step1FormQuestions.Question1}
            </StyledQuestionText>
            <StyledTextField
                select
                label="Type d'événement"
                name="eventType"
                value={formData.eventType}
                onChange={handleChange}
                margin="normal"
                error={!!errors.eventType}
                helperText={errors.eventType}
            >
                {Object.entries(EventTypes).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </StyledTextField>

            {formData.eventType === EventTypes.Other && (
                <TextField
                    name="otherEventType"
                    value={otherEventType}
                    onChange={handleOtherEventChange}
                    placeholder="Veuillez préciser"
                    margin="normal"
                    error={!!errors.otherEventType}
                    helperText={errors.otherEventType}
                />
            )}

            {/* Le matériel est-il déjà sur place? */}
            {formData.eventType === EventTypes.Public2 && (
                <>
                    <StyledQuestionText>
                        {Step1FormQuestions.Question2}
                    </StyledQuestionText>
                    <StyledTextField
                        select
                        fullWidth
                        label="Sur place?"
                        name="isMaterialInVenue"
                        value={formData.isMaterialInVenue}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.isMaterialInVenue}
                        helperText={errors.isMaterialInVenue}
                    >
                        <MenuItem value='Oui'>Oui</MenuItem>
                        <MenuItem value='Non'>Non</MenuItem>
                        <MenuItem value='Maybe'>Je ne sais pas</MenuItem>
                    </StyledTextField>
                </>
            )}

            {/* De quels matériels avez-vous besoin? */}
            {((formData.isMaterialInVenue !== null && formData.isMaterialInVenue === 'Non') || (formData.eventType !== EventTypes.Public2 && formData.eventType !== 'autre')) && (
                <>
                    <StyledQuestionText>
                        {Step1FormQuestions.Question3}
                    </StyledQuestionText>
                    <FormGroup>
                        {Object.values(MaterialOptions).map((material) => (
                            <StyledFormControlLabel
                                key={material}
                                control={
                                    <StyledCheckbox
                                        checked={formData.materialList.includes(
                                            material
                                        )}
                                        onChange={() => handleMaterialListChange(material)}
                                    />
                                }
                                label={material}
                            />
                        ))}
                    </FormGroup>
                    {formData.materialList.includes('Autre') && (
                        <TextField
                            name="otherMaterial"
                            value={otherMaterial}
                            onChange={handleOtherMaterialChange}
                            placeholder="Veuillez préciser"
                            fullWidth
                            margin="normal"
                            error={!!errors.otherMaterial}
                            helperText={errors.otherMaterial}
                        />
                    )}
                    {errors.materialList && formData.materialList.length === 0 && (
                        <ErrorText>{errors.materialList}</ErrorText>
                    )}
                </>
            )}

            {/* Où se déroule votre événement? */}
            <StyledQuestionText>
                {Step1FormQuestions.Question4}
            </StyledQuestionText>
            <StyledTextField
                select
                fullWidth
                label="Type de salle"
                name="venueType"
                value={formData.venueType}
                onChange={handleChange}
                margin="normal"
                error={!!errors.venueType}
                helperText={errors.venueType}
            >
                {formData.eventType === EventTypes.Public2 ?
                    Object.entries(VenueTypesForFestival).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))
                    :
                    Object.entries(VenueTypes).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))
                }
            </StyledTextField>
            {formData.venueType === 'Autre' && (
                <TextField
                    name="otherVenueType"
                    value={otherVenueType}
                    onChange={handleOtherVenueChange}
                    placeholder="Veuillez préciser"
                    error={!!errors.otherVenueType}
                    helperText={errors.otherVenueType}
                />
            )}

        </StepFormContainer>
    )
}

export default Step1Form