import React, { useState } from 'react'
import {
    StepFormContainer,
    StyledQuestionText,
    StyledTextField,
} from '../../styles/Forms/StepsForm.style';
import { Step3FormQuestions } from '../../enums/page_texts/contact_forms_texts';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { CalendarToday, LocationOn } from '@mui/icons-material';
import HoursPicker from './HoursPicker';

interface Step3FormProps {
    formData: {
        venueAddress: string; //UPDATE TYPE
        eventDate: string; //UPDATE TYPE
        isDateFlexible: boolean;
        startHour: string;
        endHour: string;
        otherInformation: string;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errors: {
        venueAddress?: string; //UPDATE TYPE
        eventDate?: string; //UPDATE TYPE
        isDateFlexible?: string;
        startHour?: string;
        endHour?: string;
    };
}

const Step3Form: React.FC<Step3FormProps> = ({ formData, handleChange, errors }) => {
    const [otherInformation, setOtherInformation] = useState(formData.otherInformation || '')

    const handleOtherInformationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherInformation(event.target.value);
        handleChange({
            ...event,
            target: {
                ...event.target,
                name: 'otherInformationType',
                value: event.target.value,
            },
        })
    }

    return (
        <StepFormContainer>

            {/* Quelle est l'adresse de l'événement*/}
            <StyledQuestionText>
                {Step3FormQuestions.Question1}
            </StyledQuestionText>
            <StyledTextField
                label="Adresse de l'événement"
                name="venueAddress"
                value={formData.venueAddress}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.venueAddress}
                helperText={errors.venueAddress}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOn sx={{color: 'white'}}/>
                            </InputAdornment>
                        ),
                    },
                    inputLabel: {
                        shrink: true,
                    }
                }}
            />

            {/* Quand aura lieu l'événement? */}
            <StyledQuestionText>
                {Step3FormQuestions.Question2}
            </StyledQuestionText>
            <StyledTextField
                label="Date de l'événement"
                type="date"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.eventDate}
                helperText={errors.eventDate}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <CalendarToday sx={{color: 'white'}} />
                            </InputAdornment>
                        ),
                    },
                    inputLabel: {
                        shrink: true
                    },
                }}
            />

            {/* La date est-elle flexible? */}
            <StyledQuestionText>
                {Step3FormQuestions.Question3}
            </StyledQuestionText>
            <StyledTextField
                select
                fullWidth
                label="Date flexible?"
                name="isDateFlexible"
                value={formData.isDateFlexible}
                onChange={handleChange}
                margin="normal"
                error={!!errors.isDateFlexible}
                helperText={errors.isDateFlexible}
            >
                <MenuItem value='Oui'>Oui</MenuItem>
                <MenuItem value='Non'>Non</MenuItem>
            </StyledTextField>

            {/* Horaire de prestation souhaité */}
            <StyledQuestionText>
                {Step3FormQuestions.Question4}
            </StyledQuestionText>
            <HoursPicker formData={formData} handleChange={handleChange} errors={errors} />

            {/* Information supplémentaire à savoir concernant votre événement? */}
            <StyledQuestionText>
                {Step3FormQuestions.Question5}
            </StyledQuestionText>

            <StyledTextField
                name="otherMaterial"
                value={otherInformation}
                onChange={handleOtherInformationChange}
                placeholder="Veuillez préciser"
                fullWidth
                margin="normal"
            />

        </StepFormContainer>
    )
}

export default Step3Form