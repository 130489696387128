import React from 'react'
import { StepFormContainer, StyledQuestionText, StyledTextField } from '../../styles/Forms/StepsForm.style';
import { Step4FormQuestions } from '../../enums/page_texts/contact_forms_texts';
import { MenuItem } from '@mui/material';

interface Step4FormProps {
  formData: {
    wantsArtis: boolean;
  },
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errors: {
    wantsArtis?: string;
  }
}

const Step4Form: React.FC<Step4FormProps> = ({ formData, handleChange, errors }) => {

  return (
    <StepFormContainer>
      {/* De quel type d'événement s'agit-il? */}
      <StyledQuestionText>
        {Step4FormQuestions.Question1}
      </StyledQuestionText>
      <StyledTextField
        select
        fullWidth
        label="Artis Events?"
        name="wantsArtis"
        value={formData.wantsArtis}
        onChange={handleChange}
        margin="normal"
        error={!!errors.wantsArtis}
        helperText={errors.wantsArtis}
      >
        <MenuItem value='Oui'>Oui</MenuItem>
        <MenuItem value='Non'>Non</MenuItem>
      </StyledTextField>
    </StepFormContainer>
  )
}

export default Step4Form