import React, { useState } from 'react'
import { Box, Dialog, DialogContent, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { StyledTableContainer, StyledTableCell, StyledHeaderCell, CategoryCell } from '../../styles/Tables/DoubleEntryTable.style';

import { CheckCircle, Cancel } from '@mui/icons-material'
import { FormulasPictures, PrivateEventsTableCategories, PrivateEventsTableColumnTitles, PrivateEventsTableRows, PrivateEventsTableSubcategories } from '../../enums/page_texts/formulas_texts';

const DoubleEntryTable: React.FC = () => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleImageClick = (image: string) => {
        setSelectedImage(image);
        setModalOpen(true);
    }

    const renderIcon = (value: string) => {
        if (value === 'V') {
            return <CheckCircle color="success" sx={{ fontSize: "1.9rem" }} />
        } else if (value === 'X') {
            return <Cancel color="error" />
        }
        return value
    }

    const renderImageCell = (isFormulaColumn: boolean, imageUrl?: string) => {
        return isFormulaColumn && imageUrl ? (
            <StyledTableCell>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <img
                        src={imageUrl}
                        alt="Formula"
                        style={{
                            maxWidth: "50px",
                            maxHeight: "50px",
                            border: "2px solid white",
                            cursor: "pointer",
                        }}
                        onClick={() => handleImageClick(imageUrl)}
                    />
                </Box>
            </StyledTableCell>
        ) : (
            <StyledTableCell />
        );
    };

    const formulaImages: Record<string, string> = {
        "AnniversaireEssentielle": FormulasPictures.AnniversaireEssentielle,
        "AnniversairePrestige": FormulasPictures.AnniversairePrestige,
        "MariageEssentielle": FormulasPictures.MariageEssentielle,
        "MariagePrestige": FormulasPictures.MariagePrestige,
        "EntrepriseEssentielle": FormulasPictures.EntrepriseEssentielle,
        "EntreprisePrestige": FormulasPictures.EntreprisePrestige,
    };

    return (
        <>
            <StyledTableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledHeaderCell rowSpan={2}>{PrivateEventsTableColumnTitles.CategoryTitle}</StyledHeaderCell>
                            <StyledHeaderCell rowSpan={2}>{PrivateEventsTableColumnTitles.ItemTitle}</StyledHeaderCell>
                            {Object.values(PrivateEventsTableCategories).map((category, index) => (
                                <StyledHeaderCell colSpan={2} key={category}>
                                    {category}
                                </StyledHeaderCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            {Object.values(PrivateEventsTableCategories).flatMap((category) =>
                                Object.values(PrivateEventsTableSubcategories).map((sub) => (
                                    <StyledHeaderCell key={`${category}-${sub}`} >{sub}</StyledHeaderCell>
                                ))
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <StyledTableCell colSpan={2} />
                            {Object.values(PrivateEventsTableCategories).flatMap((category) =>
                                Object.values(PrivateEventsTableSubcategories).map((sub) => {
                                    category = category === "Événement d'entreprise" ? "Entreprise" : category
                                    const key = `${category}${sub}`;
                                    return renderImageCell(Boolean(formulaImages[key]), formulaImages[key]);
                                })
                            )}
                        </TableRow>
                        {Object.entries(PrivateEventsTableRows).map(([category, row]) => (
                                <React.Fragment key={category}>
                                    {row.data.map((item, itemIndex) => (
                                        <TableRow key={itemIndex}>
                                            {itemIndex === 0 && (
                                                <CategoryCell rowSpan={row.data.length}>
                                                    {category}
                                                </CategoryCell>
                                            )}
                                            <StyledTableCell>{item[0]}</StyledTableCell>
                                            {item.slice(1).map((cell, i) => (
                                                <StyledTableCell
                                                    key={i}
                                                >
                                                    {renderIcon(cell)}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Full view"
                            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DoubleEntryTable