import React, { useState, useEffect } from 'react'
import PrivateEventsPictureGallery from './PrivateEventsPictureGallery'
import { EventsSectionContainer } from '../../styles/EventsPages/EventsSection'
import { fetchPrivateEventsGallery } from '../../services/api'
import { EventsTypes } from '../../interfaces/types'

const PrivateEventsSection: React.FC = () => {
    const [eventTypes, setEventTypes] = useState<EventsTypes[]>([])
    
    useEffect(() => {
        getEventsTypes()
    }, [])

    const getEventsTypes = async () => {
        try {
            const data = await fetchPrivateEventsGallery()
            setEventTypes(data);
        } catch (error) {
            console.error('Error fetching event types:', error);
        }
    }

    return (
        <EventsSectionContainer>
            {eventTypes?.map((event, index) => (
                <PrivateEventsPictureGallery 
                key={index} 
                eventsType={event}
                side={index % 2 === 0 ? 'right' : 'left'} />
            ))}
        </EventsSectionContainer>
    )
}

export default PrivateEventsSection