import { Box, Button, Dialog, RadioGroup, FormControlLabel, Checkbox, TextField, Typography, Stepper, styled } from "@mui/material";

export const StepFormButton = styled(Button)(({ theme }) => ({
    padding: '12px 24px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: '8px',
    boxShadow: `0px 0px 10px 2px rgba(255, 255, 255, 0.5)`,
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
        transform: 'scale(1.05)',
    },

    animation: 'pulse 2s infinite',
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.05)',
            backgroundColor: theme.palette.secondary.dark,
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
}))


export const StepFormContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    height: "50vh",
    overflowY: "auto"
}));

export const StyledQuestionText = styled(Typography)(({ theme }) => ({
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
}))

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
}));

export const StyledHorizontalRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    "& .MuiTypography-root": {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.primary.main,
    "&.Mui-checked": {
        color: theme.palette.primary.main,
    },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
    maxWidth: '20%',
    minWidth: '10%',
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.primary.dark,
        },
    },
    "& .MuiInputBase-root": {
        backgroundColor: theme.palette.background.default,
    },
    "& .MuiInputLabel-root": {
        color: theme.palette.primary.dark,
    },
    "& .Mui-focused .MuiInputLabel-root": {
        color: theme.palette.primary.main,
    },
    "& .Mui-error .MuiInputBase-root": {
        borderColor: theme.palette.error.main,
    },
    "& .MuiSelect-icon": {
        color: theme.palette.primary.dark,
    },
}));

export const CustomEquipmentContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontSize: "0.875rem",
    marginTop: theme.spacing(0.5),
}));

export const StyledStepper = styled(Stepper)(({ theme }) => ({
    backgroundColor: "transparent",
    "& .MuiStepConnector-line": {
        borderColor: theme.palette.info.main,
    },
    '&.Mui-disabled .MuiStepLabel-label': {
        color: theme.palette.primary.dark,
    },
    "& .MuiStepLabel-label.Mui-disabled": {
        color: theme.palette.secondary.light,
    },
    "& .MuiStepIcon-root": {
        color: theme.palette.secondary.light,
    },
    "& .Mui-active .MuiStepIcon-root": {
        color: theme.palette.primary.main,
    },
    "& .Mui-completed .MuiStepIcon-root": {
        color: theme.palette.primary.main,
    },
}));


export const StyledFormContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogTitle-root": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.dark,
    },
    "& .MuiDialogActions-root": {
        display: "flex",
        justifyContent: "right",
        backgroundColor: theme.palette.secondary.main,
    },
}));

export const StickyStepFormModalWrapper = styled('div')({
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    zIndex: 9999,
});