import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const Overlay = styled('div')(() => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
}))

export const ModalContent = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    padding: theme.spacing(4),
    width: '20%',
    height: '50%',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',

    backgroundImage: `url('/images/mehdi_vertical.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        filter: 'blur(5px)',
        zIndex: 1,
    },
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));


export const ModalTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 700,
}));

export const StyledModalList = styled('ul')(({ theme }) => ({
    fontSize: '1.2rem',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    listStyleType: 'none',
}));

export const StyledModalListItem = styled('li')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const ModalText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
}));

export const CloseButton = styled('button')(({ theme }) => ({
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 600,
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
}));