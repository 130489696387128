export enum EventsGallery {
    Public1 = '/images/public1.jpg',
    Public2 = '/images/public2.jpg',
    Public3 = '/images/public3.jpg',
    Private1 = '/images/private1.jpg',
    Private2 = '/images/private2.jpg',
    Private3 = '/images/private3.jpg',
}

export const MenuPictures: {Main: string, HomePicture: string, PublicEventsPicture: string, PrivateEventsPicture: string, FormulasPicture: string, FormPicture: string,} = {
    Main: '',
    HomePicture: '',
    PublicEventsPicture: '',
    PrivateEventsPicture: '',
    FormulasPicture: '',
    FormPicture: '',
} as const

export enum MaydayLogos {
    MainLogo = '/logos/mayday.svg',
}


export const TopSectionsPictures: { HomePage: string, PrivateEventsPage: string, PublicEventsPage: string, FormulasPage: string} = {
    HomePage: '',
    PrivateEventsPage: '',
    PublicEventsPage: '',
    FormulasPage: '',
} as const

export const HomeEventsSectionPictures: { PrivateEventsPicture: string, PublicEventsPicture: string} = {
    PrivateEventsPicture: '',
    PublicEventsPicture: '',
} as const

export const HomeHistorySectionPictures: { MainPicture: string, TopLeftPicture: string, TopRightPicture: string, BottomLeftPicture: string, BottomRightPicture: string } = {
    MainPicture: '',
    TopLeftPicture: '',
    TopRightPicture: '',
    BottomLeftPicture: '',
    BottomRightPicture: '',
} as const 