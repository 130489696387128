import React from 'react'
import { StyledTextField } from '../../styles/Forms/StepsForm.style';

interface HoursPickerProps {
    formData: {
        startHour: string;
        endHour: string;
    },
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errors: {
        startHour?: string;
        endHour?: string;
    }
}

const HoursPicker:React.FC<HoursPickerProps> = ({formData, handleChange, errors}) => {
  return (
    <>
        {/* Start Hour Picker */}
      <StyledTextField
        label="Heure de début"
        name="startHour"
        type="time"
        value={formData.startHour}
        onChange={handleChange}
        fullWidth
        margin="normal"
        error={!!errors.startHour}
        helperText={errors.startHour}
        inputProps={{
          step: 300,
        }}
      />

      {/* End Hour Picker */}
      <StyledTextField
        label="Heure de fin"
        name="endHour"
        type="time"
        value={formData.endHour}
        onChange={handleChange}
        fullWidth
        margin="normal"
        error={!!errors.endHour}
        helperText={errors.endHour}
        inputProps={{
          step: 300,
        }}
      />
    </>
  )
}

export default HoursPicker