import React, { useState, useEffect } from 'react'
import PublicEventsPictureGallery from './PublicEventsPictureGallery'
import { EventsTypes } from '../../interfaces/types'
import { EventsSectionContainer } from '../../styles/EventsPages/EventsSection'
import { fetchPublicEventsGallery } from '../../services/api'

const PublicEventsSection: React.FC = () => {
    const [eventTypes, setEventTypes] = useState<EventsTypes[]>([])

    useEffect(() => {
        getEventsTypes()
    }, [])

    const getEventsTypes = async () => {
        try {
            const data = await fetchPublicEventsGallery()
            setEventTypes(data)
        } catch (error) {
            console.error('Error fetching event types:', error);
        }
    }
    return (
        <EventsSectionContainer>
            {eventTypes.map((event, index) => (
                <PublicEventsPictureGallery 
                key={index} 
                eventsType={event}
                side={index % 2 === 0 ? 'right' : 'left'} />
            ))}
        </EventsSectionContainer>
    )
}

export default PublicEventsSection