import { styled } from '@mui/system';
import { FormControlLabel, Box } from '@mui/material';

export const SectionContainer = styled('section')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: `radial-gradient(circle, ${theme.palette.secondary.light} 1px, transparent 1px)`,
  backgroundSize: '20px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const StyledBackgroundDiv = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: `radial-gradient(circle, ${theme.palette.secondary.light} 1px, transparent 1px)`,
  backgroundSize: '20px 20px',
}))



export const StyledRadioGroupContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: 'center',
  boxShadow: `0px 0px 10px 2px rgba(255, 255, 255, 0.5)`,
  margin: `${theme.spacing(4)} auto`,
  width: 'auto',
  maxWidth: '70%',
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiTypography-root": {
      color: theme.palette.text.primary,
      fontWeight: 700,
  },
  "& .Mui-checked": {
      color: theme.palette.info.main,
  },
}));