import React, { useState } from 'react'
import { MenuItem, FormGroup, TextField } from '@mui/material'
import { ErrorText, StepFormContainer, StyledCheckbox, StyledFormControlLabel, StyledQuestionText, StyledTextField } from '../../styles/Forms/StepsForm.style';
import { AgeOptions, BudgetOptions, MusicStyles, Step2FormQuestions } from '../../enums/page_texts/contact_forms_texts';

interface Step2FormProps {
  formData: {
    venueType: string;
    otherVenueType?: string;
    musicStylesList: string[];
    otherMusicStyle?: string;
    guestsAge: string;
    budget: string;
    guestsAmount: string;
  };
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleMusicStylesListChange: (musicStyle: string) => void;
  errors: {
    musicStylesList?: string;
    otherMusicStyle?: string;
    guestsAge?: string;
    budget?: string;
    guestsAmount?: string;
  };
}

const Step2Form: React.FC<Step2FormProps> = ({ formData, handleChange, handleMusicStylesListChange, errors }) => {
  const [otherMusicStyle, setOtherMusicStyle] = useState(formData.otherMusicStyle || '')

  const handleOtherMusicStyleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherMusicStyle(event.target.value);
    handleChange({
      ...event,
      target: {
        ...event.target,
        name: 'otherMusicStyle',
        value: event.target.value,
      },
    })
  }

  return (
    <StepFormContainer>
      {/* Style de musique souhaitée? */}
      <StyledQuestionText>
        {Step2FormQuestions.Question1}
      </StyledQuestionText>
      <>
        <FormGroup>
          {Object.values(MusicStyles).map((musicStyle) => (
            <StyledFormControlLabel
              key={musicStyle}
              control={
                <StyledCheckbox
                  checked={formData.musicStylesList.includes(
                    musicStyle
                  )}
                  onChange={() => handleMusicStylesListChange(musicStyle)}
                />
              }
              label={musicStyle}
            />
          ))}
        </FormGroup>
        {formData.musicStylesList.includes('Autre') && (
          <TextField
            name="otherMusicStyle"
            value={otherMusicStyle}
            onChange={handleOtherMusicStyleChange}
            placeholder="Veuillez préciser"
            fullWidth
            margin="normal"
            error={!!errors.otherMusicStyle}
            helperText={errors.otherMusicStyle}
          />
        )}
        {errors.musicStylesList && formData.musicStylesList.length === 0 && (
          <ErrorText>{errors.musicStylesList}</ErrorText>
        )}
      </>

      {/* Quel âge ont les invités? */}
      <StyledQuestionText>
        {Step2FormQuestions.Question2}
      </StyledQuestionText>

      <StyledTextField
        select
        fullWidth
        label="Âge invités"
        name="guestsAge"
        value={formData.guestsAge}
        onChange={handleChange}
        margin="normal"
        error={!!errors.guestsAge}
        helperText={errors.guestsAge}
      >
        {Object.entries(AgeOptions).map(([key, value]) => (
          <MenuItem key={key} value={value}>
            {value}
          </MenuItem>
        ))}
      </StyledTextField>

      {/* Quel est votre budget approximatif? */}
      <StyledQuestionText>
        {Step2FormQuestions.Question3}
      </StyledQuestionText>

      <StyledTextField
        select
        fullWidth
        label="Budget"
        name="budget"
        value={formData.budget}
        onChange={handleChange}
        margin="normal"
        error={!!errors.budget}
        helperText={errors.budget}
      >
        {Object.entries(BudgetOptions).map(([key, value]) => (
          <MenuItem key={key} value={value}>
            {value}
          </MenuItem>
        ))}
      </StyledTextField>

      {/* Quel est le nombre prévu d'invités? */}
      <StyledQuestionText>
        {Step2FormQuestions.Question4}
      </StyledQuestionText>
      <StyledTextField
          fullWidth
          label="Nombre de participants prévus"
          name="guestsAmount"
          type="number"
          value={formData.guestsAmount}
          onChange={handleChange}
          margin="normal"
          inputProps={{
            min: 0,
            max: 10000,
          }}
          error={!!errors.guestsAmount}
          helperText={errors.guestsAmount}
        />
    </StepFormContainer>
  )
}

export default Step2Form