export const FooterMainTexts: { LeftTitle: string, Column1: string, Column2: string, Column3: string, Column4: string, } = {
    LeftTitle: '',
    Column1: '',
    Column2: '',
    Column3: '',
    Column4: '',
} as const;

export const FooterColumn1Items: { Item1: string, Item2: string, Item3: string,} = {
    Item1: '',
    Item2: '',
    Item3: '',
} as const

export const FooterColumn2Items: { Item1: string, Item2: string, Item3: string, Link1: string, Link2: string, Link3: string, } = {
    Item1: '',
    Item2: '',
    Item3: '',
    Link1: '',
    Link2: '',
    Link3: '',
} as const

export const FooterColumn3Items: { Item1: string, Item2: string, Item3: string, Link1: string, Link2: string, Link3: string, } = {
    Item1: '',
    Item2: '',
    Item3: '',
    Link1: '',
    Link2: '',
    Link3: '',
} as const

export const FooterColumn4Items: { Item1: string, Item2: string, Item3: string, Link1: string, Link2: string, Link3: string, } = {
    Item1: '',
    Item2: '',
    Item3: '',
    Link1: '',
    Link2: '',
    Link3: '',
} as const


