import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';

export const EventsSectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}))

export const CarouselContainer = styled(Box)({
  position: 'relative',
  width: '50%',
  maxWidth: '600px',
  height: '50%',
  maxHeight: '400',
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
})

export const CarouselImage = styled('img')({
  width: '100%',
  height: '100%',
  display: 'block',
  objectFit: 'cover'
})

export const CarouselOverlay = styled(Box)({
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: '#fff',
  padding: '5px 10px',
  borderRadius: '4px',
})

export const CarouselWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  paddingTop: '5%',
  paddingBottom: '5%'
})

export const EventTitle = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: '10%',
  alignSelf: 'center',
}))

export const EventDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.primary.dark,
  maxWidth: '500px',
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  color: 'white',
  zIndex: 10,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '12px',
  boxShadow: 'rgba(0, 0, 0, 1.0)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    cursor: 'pointer',
  },
}));