import React from 'react'
import {
    HistorySectionContainer,
    ImageCollage,
    MainImage,
    SmallImage,
    TextContent,
    BigTitle,
    SubTitle,
    Paragraph,
    ReadMoreButton,
} from '../../../styles/Homepage/HistorySection.style'
import { HistorySectionTexts } from '../../../enums/page_texts/homepage_texts';

interface HistorySectionProps {
    title: string;
    subtitle: string;
    mainImage: string;
    smallImages: string[];
    description: string;
}


const HistorySection: React.FC<HistorySectionProps> = ({title, subtitle, mainImage, smallImages, description}) => {
    return (
        <HistorySectionContainer>
            {/* Left Side - Image Collage */}
            <ImageCollage>
                <MainImage src={mainImage} alt="Main DJ Image" />
                {smallImages.map((image, index) => {
                    const positions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'] as const;
                    return <SmallImage key={index} src={image} alt={`Small Image ${index + 1}`} position={positions[index]} />;
                })}
            </ImageCollage>

            {/* Right Side - Text Content */}
            <TextContent>
                <BigTitle>{title}</BigTitle>
                <SubTitle>{subtitle}</SubTitle>
                <Paragraph>
                {description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </Paragraph>
                {/* <ReadMoreButton>{HistorySectionTexts.ButtonText}</ReadMoreButton> */}
            </TextContent>
        </HistorySectionContainer>
    );
}

export default HistorySection