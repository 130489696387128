import { Box, Button, styled, Typography } from '@mui/material';

interface IntroSectionProps {
  backgroundImage: string;
}

export const IntroSection = styled(Box)<IntroSectionProps>(({ theme, backgroundImage }) => ({
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  textAlign: 'center',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

export const IntroContent = styled(Box)({
  position: 'relative',
  zIndex: 2,
  padding: '2rem',
});

export const BoldMainTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(40),
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '9rem',
  },
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

export const DescriptiveText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2rem',
  },
  color: theme.palette.primary.main
}));

export const DetailsButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main
  },
  display: 'inline-block',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem',
  },
  height: '50px',
}))