// export enum PrivateEventsTexts {
//     Title = 'Private Events',
//     DescriptionText = 'Viens bouger ton boule avec tes copains et DJ Mayday',
//     ModalText = 'lorem ipsum bnrj"b fjhezfb hlerfnbhjlukjnflnjklezfnkjezkjnfkjn',
//     ButtonText = 'Text du bouton',
//     AboutModal = 'about',
//     CloseModal = 'close'
// }

export const PrivateEventsTexts: {Title: string, DescriptionText: string, ModalText: string, ButtonText: string, AboutModal: string, CloseModal: string} = {
    Title: '',
    DescriptionText: '',
    ModalText: '',
    ButtonText: '',
    AboutModal: '',
    CloseModal: '',
}