export const GeneralTexts: { Name: string } = {
    Name: '',
} as const

export const HomeIntroTexts: { DescriptiveText: string, ButtonText: string, ModalText: string, CloseModal: string, AboutModal: string,  } = {
    DescriptiveText: '',
    ButtonText: '',
    ModalText: '',
    CloseModal: '',
    AboutModal: ''
} as const

export const ScrollingTexts: {Row1Text1: string, Row1Text2: string, Row1Text3: string, Row2Text1: string, Row2Text2: string, Row2Text3: string, } = {
    Row1Text1: '',
    Row1Text2: '',
    Row1Text3: '',
    Row2Text1: '',
    Row2Text2: '',
    Row2Text3: '',
} as const

export const SocialMedias: {Facebook: string, Instagram: string, SoundCloud: string, YouTube: string, TikTok: string, } = {
    Facebook: '',
    Instagram: '',
    SoundCloud: '',
    YouTube: '',
    TikTok: '',
} as const

export const SocialMediaLinks: {Facebook: string, Instagram: string, SoundCloud: string, YouTube: string, TikTok: string, Email: string, } = {
    Facebook: '',
    SoundCloud: '',
    Instagram: '',
    YouTube: '',
    TikTok: '',
    Email: '',
} as const

export const HistorySectionTexts: {Title: string, Subtitle: string, Description: string, ButtonText: string} = {
    Title: '',
    Subtitle: '',
    Description: '',
    ButtonText: '',
} as const

export const DescriptiveSectionTexts: {Title: string} = {
    Title: ''
} as const