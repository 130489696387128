import React, { useState, useEffect, useRef } from 'react'
import { ScrapbookContainer, ScrapbookItem } from '../../../styles/Homepage/ReviewsSection.style';
import { CardMedia, Typography } from '@mui/material';
import { PhotoOrReview } from '../../../interfaces/types';

interface ScrapbookProps {
    items: PhotoOrReview[];
}

const ITEM_SIZE = 200;

const ReviewsSection: React.FC<ScrapbookProps> = ({ items }) => {
    const [gridSize, setGridSize] = useState<number>(0)
    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const numItems = items.length;
        const sqrt = Math.ceil(Math.sqrt(numItems));
        setGridSize(sqrt);
    }, [items.length]);

    const getRandomGridPosition = (index: number): { top: string; left: string } => {
        const containerWidth = containerRef.current?.offsetWidth || 0;
        const containerHeight = containerRef.current?.offsetHeight || 0;

        const row = Math.floor(index / gridSize);
        const col = index % gridSize;

        const heightOffset = (row < gridSize / 2 ? -0.3 : 0) * containerHeight;
        const widthOffset = (col < gridSize / 3 ? -0.35 : -0.3) * containerWidth;

        const top = row * (ITEM_SIZE + 20) + heightOffset;
        const left = col * (ITEM_SIZE + 40) + widthOffset;
        return { top: `${top}px`, left: `${left}px` };
    };

    return (
            <ScrapbookContainer>
                {items.map((item, index) => {
                    const randomRotation = Math.floor(Math.random() * 15) - 7;

                    const { top, left } = getRandomGridPosition(index);

                    const zIndex = item.type === 'text' ? 10 : 1;

                    return (
                        <ScrapbookItem
                            key={index}
                            rotation={randomRotation}
                            top={top}
                            left={left}
                            style={{ zIndex }}
                        >
                            {item.type === 'image' ? (
                                <CardMedia component="img" height="200" image={item.image} />
                            ) : (
                                <Typography variant="body1">{item.content}</Typography>
                            )}
                        </ScrapbookItem>
                    );
                })}
            </ScrapbookContainer>
    )
}

export default ReviewsSection
