import React, { useEffect, useState } from 'react'

import Header from '../menu/Header'
import Footer from '../footer/Footer'
import PageIntro from '../homepage/intro_section/IntroSection'

import { ContactFormsTexts } from '../../enums/page_texts/contact_forms_texts'
import { TopSectionsPictures } from '../../enums/images'
import StepFormModal from './StepFormModal'
import EventTypeSelector from '../general/EventTypeSelector'
import DoubleEntryTable from '../formulas/DoubleEntryTable'
import RollingGallery from '../general/RollingGallery'
import { StyledBackgroundDiv } from '../../styles/common.style'
import PublicEventsFormulasTable from '../formulas/PublicEventsFormulasTable'

import { fetchExtraCards, loadContactFormData, loadFomulasPageData } from '../../services/api'
import { FormulasPageGeneralTexts, PrivateEventsDescription, PublicEventsDescription, PublicEventsFormulasTableTexts } from '../../enums/page_texts/formulas_texts'
import LoadingSpinner from '../general/LoadingSpinner'
import StaticFooter from '../footer/StaticFooter'
import { Typography } from '@mui/material'
import EventTypeDescriptionSection from '../formulas/EventTypeDescriptionSection'

interface EventDescription {
  subtitle: string;
  text: string;
  imageUrl: string;
}

interface ContactFormsPageProps {
  isOpen?: boolean,
}

const ContactFormsPage: React.FC<ContactFormsPageProps> = ({isOpen = false}) => {
  const [loading, setLoading] = useState(true);

  const [eventType, setEventType] = useState<'private' | 'public'>('private')
  // const [artisCatalog, setArtisCatalog] = useState<'yes' | 'no'>('yes')

  const [descriptionPrivateEvents, setDescriptionPrivateEvents] = useState<EventDescription[]>([])
  const [descriptionPublicEvents, setDescriptionPublicEvents] = useState<EventDescription[]>([])
  const [extraCards, setExtraCards] = useState<any[]>([]);

  useEffect(() => {
    const loadTexts = async () => {
      await loadFomulasPageData()
      await loadContactFormData()

      const privateEvents = [
        {
          subtitle: PrivateEventsDescription.Subtitle1,
          text: PrivateEventsDescription.Text1,
          imageUrl: '/images/private1.jpg',
        },
        {
          subtitle: PrivateEventsDescription.Subtitle2,
          text: PrivateEventsDescription.Text2,
          imageUrl: '/images/private2.jpg',
        },
        {
          subtitle: PrivateEventsDescription.Subtitle3,
          text: PrivateEventsDescription.Text3,
          imageUrl: '/images/private3.jpg',
        }
      ];

      const publicEvents = [
        {
          subtitle: PublicEventsDescription.Subtitle1,
          text: PublicEventsDescription.Text1,
          imageUrl: '/images/private3.jpg',
        },
        {
          subtitle: PublicEventsDescription.Subtitle2,
          text: PublicEventsDescription.Text2,
          imageUrl: '/images/private3.jpg',
        },
        {
          subtitle: PublicEventsDescription.Subtitle3,
          text: PublicEventsDescription.Text3,
          imageUrl: '/images/private3.jpg',
        },
      ];

      setDescriptionPrivateEvents(privateEvents)
      setDescriptionPublicEvents(publicEvents)
      setLoading(false);
    }

    const loadExtraCards = async () => {
      const data = await fetchExtraCards();
      setExtraCards(data)
    }

    loadExtraCards();
    loadTexts();
  }, [])

  const handleEventTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEventType(event.target.value as 'private' | 'public');
  };

  // const handleArtisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setArtisCatalog(event.target.value as 'yes' | 'no');
  // };

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <StyledBackgroundDiv>
      <Header />
      <PageIntro title={ContactFormsTexts.Title}
        description={ContactFormsTexts.DescriptionText}
        backgroundImage={TopSectionsPictures.FormulasPage}
        modalText={ContactFormsTexts.ModalText}
        buttonText={ContactFormsTexts.ButtonText}
        aboutModal={ContactFormsTexts.AboutModal}
        closeModal={ContactFormsTexts.CloseModal} />

      <Typography sx={{
        color: 'white',
        fontSize: '1.2rem',
        lineHeight: '1.5',
        margin: '5%',
        textAlign: 'justify'
      }}>
        {FormulasPageGeneralTexts.ExplanationText}
      </Typography>
      <EventTypeSelector eventType={eventType} onChange={handleEventTypeChange} />
      {/* <CloudBorder /> */}
      {eventType === 'private' &&
        <>
          {/* <DescriptiveSection title={FormulasPageGeneralTexts.PrivateFormulasTitle} description={descriptionPrivateEvents} align='left' /> */}
          <EventTypeDescriptionSection descriptions={descriptionPrivateEvents} />
          <DoubleEntryTable />
          <RollingGallery title={FormulasPageGeneralTexts.ExtraTitle} cards={extraCards} />
          {/* <ArtisProposition artisCatalog={artisCatalog} onChange={handleArtisChange} /> */}
        </>
      }

      {eventType === 'public' &&
        <>
          {/* <DescriptiveSection title={FormulasPageGeneralTexts.PublicFormulasTitle} description={descriptionPublicEvents} align='right' /> */}
          <EventTypeDescriptionSection descriptions={descriptionPublicEvents} />
          <PublicEventsFormulasTable formulasData={PublicEventsFormulasTableTexts} />
          <RollingGallery title={FormulasPageGeneralTexts.ExtraTitle} cards={extraCards} />
          {/* <ArtisProposition artisCatalog={artisCatalog} onChange={handleArtisChange} /> */}
        </>
      }

      <StepFormModal isOpen={isOpen}/>
      <StaticFooter />
      <Footer />
    </StyledBackgroundDiv>
  )
}

export default ContactFormsPage