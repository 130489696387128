import { Box, Card } from '@mui/material'
import { styled } from '@mui/system'

interface ScrapbookItemProps {
    rotation: number;
    top: string;
    left: string;
  }

export const ScrapbookContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    position: 'relative',
    padding: '40px',
})

export const ScrapbookItem = styled(Card)<ScrapbookItemProps>(({ rotation, top, left }) => ({
    position: 'relative',
    transform: `rotate(${rotation}deg)`,
    width: '180px',
    padding: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: `rotate(${rotation + 2}deg) scale(1.05)`,
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.7)',
    },
  }));