import { styled } from '@mui/system';
import { Box, IconButton, Typography } from '@mui/material';

interface FooterBoxProps {
    isAtBottom: boolean;
  }

  export const StyledFooterBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isAtBottom', // Prevent `isAtBottom` from being passed to the DOM
  })<FooterBoxProps>(({ isAtBottom }) => ({
    position: 'fixed',
    bottom: 0,
    width: isAtBottom ? '100%' : '30%',
    height: '3%',
    transform: isAtBottom ? '' : 'translateX(115%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    padding: 2,
    zIndex: 1200,
    transition: 'padding 0.5s ease, opacity 0.5s ease',
  }));
  
  export const SocialIconsContainer = styled(Box)(() => ({
    display: 'flex',
    gap: 2,
  }));
  
  export const StyledIconButton = styled(IconButton)(() => ({
    color: 'white',
    '&:hover': { color: 'primary.main' },
    transition: 'color 0.3s',
  }));
  
  export const SocialMediaText = styled(Typography)(({theme}) => ({
    marginLeft: 8,
  }));