import Header from '../menu/Header'
import PageIntro from '../homepage/intro_section/IntroSection'
import { TopSectionsPictures } from '../../enums/images'
import { PublicEventsTexts } from '../../enums/page_texts/public_events_texts'
import PublicEventsSection from './PublicEventsSection'
import Footer from '../footer/Footer'
import StaticFooter from '../footer/StaticFooter'
import { useEffect, useState } from 'react'
import { loadPublicEventsPageData } from '../../services/api'
import LoadingSpinner from '../general/LoadingSpinner'

function PublicEventsPage() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTexts = async () => {
      await loadPublicEventsPageData()
      setLoading(false)
    }

    loadTexts()
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <Header />
      <PageIntro title={PublicEventsTexts.Title}
        description={PublicEventsTexts.DescriptionText}
        backgroundImage={TopSectionsPictures.PublicEventsPage}
        modalText={PublicEventsTexts.ModalText}
        aboutModal={PublicEventsTexts.AboutModal}
        closeModal={PublicEventsTexts.CloseModal}
        buttonText={PublicEventsTexts.ButtonText} />
      <PublicEventsSection />
      <StaticFooter />
      <Footer />
    </div>
  )
}

export default PublicEventsPage