import React, {useEffect, useState} from "react";
import { fetchMessage } from "../../services/api";

const MessageDisplay: React.FC = () => {
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        const getMessage = async () => {
            try {
                const msg = await fetchMessage()
                setMessage(msg)
            } catch (error) {
                console.error("No message received.", error)
            }
        }

        getMessage()
    }, [])

    return(
        <div>
            <h1>Message from Django API:</h1>
            <p>{message}</p>
        </div>
    )
}

export default MessageDisplay