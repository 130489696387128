import React, { useEffect, useState } from 'react'
import { IconButton, Toolbar, Typography } from '@mui/material'
import { GeneralTexts, SocialMediaLinks } from '../../enums/page_texts/homepage_texts'
import { MaydayLogos, MenuPictures } from '../../enums/images'
import {
  LogoLink, StyledAppBar, MenuButton,
  FullScreenDialog, DialogContentContainer, StyledLink,
  StyledImage, CenteredMenuContent, LineContainer, MenuText,
  FooterContainer, FooterLogo, DividerLine, SocialMediaIcons,
  CloseButtonContainer,
} from '../../styles/Navigation/menu.style'
import { Close, Instagram, Facebook, YouTube } from '@mui/icons-material';
import { FaSoundcloud, FaTiktok } from 'react-icons/fa';
import AvailableViews from '../../enums/views'
import { MenuOptions } from '../../enums/menu_options'
import { fetchMenuPictures } from '../../services/api'



const menuOptions = [
  { label: MenuOptions.Home, to: AvailableViews.Main, picture: MenuPictures.HomePicture },
  { label: MenuOptions.PrivateEvents, to: AvailableViews.PrivateEvents, picture: MenuPictures.PrivateEventsPicture },
  { label: MenuOptions.PublicEvents, to: AvailableViews.PublicEvents, picture: MenuPictures.PublicEventsPicture },
  { label: MenuOptions.PaymentForms, to: AvailableViews.ContactForms, picture: MenuPictures.FormulasPicture },
  { label: MenuOptions.ContactForm, to: AvailableViews.ContactFormWithFormOpen, picture: MenuPictures.FormPicture },
];

const Header: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [isClosing, setIsClosing] = useState(false);
  const [displayedPicture, setDisplayedPicture] = useState(MenuPictures.Main)

  useEffect(() => {
    const loadPictures = async() => {
      await fetchMenuPictures()
    }

    loadPictures()
  }, [])

  const handleHover = (image: string) => {
    setDisplayedPicture(image)
  }

  const handleOpen = () => {
    setOpen(true)
    setIsClosing(false)
  }

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setOpen(false);
      setDisplayedPicture(MenuPictures.Main)
    }, 1000);
  }

  return (
    <>
      <StyledAppBar >
        <Toolbar>
          <LogoLink to="/">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {GeneralTexts.Name}
            </Typography>
          </LogoLink>

          <MenuButton onClick={handleOpen}>Menu</MenuButton>
        </Toolbar>
      </StyledAppBar>

      <FullScreenDialog open={open} onClose={handleClose} fullScreen>
        <DialogContentContainer>

          <CloseButtonContainer>
            <IconButton onClick={handleClose} aria-label="close">
              <Close sx={{ color: 'white' }} />
            </IconButton>
          </CloseButtonContainer>

          <StyledImage key={displayedPicture} src={displayedPicture} alt="Menu Image" className={isClosing ? 'closing' : ''} />

          <LineContainer className={isClosing ? 'closing' : ''} />
          <MenuText className={isClosing ? 'closing' : ''}>Menu</MenuText>

          <CenteredMenuContent className={isClosing ? 'closing' : ''}>
            {menuOptions.map((option, index) => (
              <StyledLink
                key={option.label}
                to={option.to}
                index={index}
                onClick={handleClose}
                onMouseEnter={() => handleHover(option.picture)}
                className={isClosing ? 'closing' : ''}
              >
                {option.label}
              </StyledLink>
            ))}
          </CenteredMenuContent>
        </DialogContentContainer>

        <FooterContainer>
          <FooterLogo className={isClosing ? 'closing' : ''}>
            <img src={MaydayLogos.MainLogo} alt="Logo" />
          </FooterLogo>

          <DividerLine className={isClosing ? 'closing' : ''} />

          <SocialMediaIcons className={isClosing ? 'closing' : ''}>
            <a href={SocialMediaLinks.Instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <Instagram />
            </a>
            <a href={SocialMediaLinks.Facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <Facebook />
            </a>
            <a href={SocialMediaLinks.YouTube} target="_blank" rel="noopener noreferrer" aria-label="Youtube">
              <YouTube />
            </a>
            <a href={SocialMediaLinks.TikTok} target="_blank" rel="noopener noreferrer" aria-label="Tiktok">
              <FaTiktok />
            </a>
            <a href={SocialMediaLinks.SoundCloud} target="_blank" rel="noopener noreferrer" aria-label="Soundcloud">
              <FaSoundcloud />
            </a>
          </SocialMediaIcons>
        </FooterContainer>
      </FullScreenDialog >
    </>
  );
};

export default Header;