import { Box, Typography } from '@mui/material'

import { styled } from '@mui/system'
export const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '1rem 2rem',
    backgroundColor: theme.palette.secondary.main,
    boxSizing: 'border-box',
    zIndex: 100,
}))

export const FooterLogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
}))

export const FooterLogo = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    '& img': {
        width: '50px',
        height: 'auto',
        borderRadius: '5px',
    },
}))

export const DividerLine = styled('div')(() => ({
    width: '80%',
    height: '1px',
    backgroundColor: 'white',
    margin: '1rem 0',
}))

export const SocialMediaIcons = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    '& svg': {
        fontSize: '1.8rem',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}))

export const FooterColumns = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    marginTop: '1rem',
    padding: '0 2rem',
    boxSizing: 'border-box',
    marginBottom: '1rem',
}))

export const Column = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h6': {
        marginBottom: '0.5rem',
        color: 'white',
        alignSelf: 'center',
    },
    '& a': {
        color: 'white',
        textDecoration: 'none',
        marginBottom: '0.5rem',
        alignSelf: 'flex-start',
        '&:hover': {
            color: 'orange',
        },
    },
}))

export const FooterTypography = styled(Typography)(({ theme }) => ({
    marginLeft: '10px',
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
}))