import { Typography } from '@mui/material'
import React from 'react'
import { StyledBigTitle, StyledBox, StyledDivider, StyledPaper, TitleTypography } from '../../styles/DescriptiveSection.style'
import { DescriptiveSectionTexts } from '../../enums/page_texts/homepage_texts';

interface DescriptionItem {
    subtitle: string;
    text: string;
}

interface DescriptiveSectionProps {
    title: string;
    description?: DescriptionItem[];
    align?: 'left' | 'right';
    children?: React.ReactNode;
}

const DescriptiveSection: React.FC<DescriptiveSectionProps> = ({ title, description, align = 'right', children }) => {
    const hasEmptyTitle = title === ""
    const hasDescription = description && description.length > 0;
    const hasChildren = Boolean(children);

    return (
        <StyledBox align={align}>
            {hasEmptyTitle &&
                <StyledBigTitle>
                    {DescriptiveSectionTexts.Title}
                </StyledBigTitle>
            }
            <StyledPaper align={align} >
                {!hasEmptyTitle &&
                    <>
                        <TitleTypography>
                            {title}
                        </TitleTypography>
                        <StyledDivider />
                    </>
                }
                {hasDescription && !hasChildren &&
                    description.map((item, index) => (
                        <div key={index}>
                            <Typography variant="h6" component="h3" gutterBottom>
                                {item.subtitle}
                            </Typography>
                            <Typography variant="body1" paragraph>
                                {item.text}
                            </Typography>
                        </div>
                    ))
                }
                {hasChildren && !hasDescription &&
                    <div style={{ marginLeft: '4vh' }}>{children}</div>
                }
            </StyledPaper>
        </StyledBox>
    )
}

export default DescriptiveSection