import React, { useState, useRef, useEffect } from 'react';
import { CardMedia, Dialog, DialogContent } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { StyledCard, StyledCardsContainer, StyledCardText, StyledGalleryContainer, StyledGalleryWrapper, StyledLeftArrowIconButton, StyledRightArrowIconButton } from '../../../styles/Homepage/GallerySection.style';

interface CardProps {
    name: string;
    image: string;
}

interface GalleryProps {
    title: string;
    cards: CardProps[];
}

const GallerySection: React.FC<GalleryProps> = ({ title, cards }) => {
    const galleryRef = useRef<HTMLDivElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const scrollGallery = () => {
        const gallery = galleryRef.current;
        if (!gallery) return;

        const cardWidth = gallery.children[0]?.getBoundingClientRect().width || 0;
        gallery.scrollBy({ left: cardWidth, behavior: 'smooth' });

        if (gallery.scrollLeft + gallery.offsetWidth >= gallery.scrollWidth) {
            gallery.scrollTo({ left: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const intervalId = setInterval(scrollGallery, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleImageClick = (image: string) => {
        setSelectedImage(image);
        setModalOpen(true);
    };

    return (
        <>
            <StyledGalleryContainer>
                <StyledGalleryWrapper>
                    {/* Left Arrow */}
                    <StyledLeftArrowIconButton
                        onClick={() => scrollGallery()}
                        aria-label="Scroll left"
                    >
                        <ArrowBackIos />
                    </StyledLeftArrowIconButton>

                    {/* Cards Container */}
                    <StyledCardsContainer ref={galleryRef}>
                        {cards.map((card, index) => (
                            <StyledCard
                                key={index}
                                onClick={() => handleImageClick(card.image)}
                            >
                                <CardMedia component="img" src={card.image} alt={card.name} />
                                <StyledCardText>{card.name}</StyledCardText>
                            </StyledCard>
                        ))}
                    </StyledCardsContainer>

                    {/* Right Arrow */}
                    <StyledRightArrowIconButton
                        onClick={() => scrollGallery()}
                        aria-label="Scroll right"
                    >
                        <ArrowForwardIos />
                    </StyledRightArrowIconButton>
                </StyledGalleryWrapper>
            </StyledGalleryContainer>

            {/* Modal pour afficher l'image en grand */}
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="md" fullWidth>
                <DialogContent>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Full view"
                            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GallerySection;
