import { createTheme, darken, lighten } from '@mui/material/styles';

const breakpoints = {
    down: (key: string) => `@media (max-width: ${key})`,
    up: (key: string) => `@media (min-width: ${key})`,
};

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h1: {
            [breakpoints.down('sm')]: {
                fontSize: '1rem',
            },
            [breakpoints.up('md')]: {
                fontSize: '5rem',
            },
            [breakpoints.up('lg')]: {
                fontSize: '7rem',
            },
            fontWeight: 700,
            color: '#F1F1F9',
        },
        h2: {
            fontWeight: 500,
            color: '#F1F1F9',
        },
        body1: {
            fontWeight: 400,
            color: '#0F1116',
        },
        button: {
            textTransform: 'uppercase',
            fontWeight: 600,
            fontSize: '0.875rem',
        },
    },
    palette: {
        primary: {
            main: '#F1F1F9',
            dark: darken('#F1F1F9', 0.2),
            light: lighten('#F1F1F9', 0.2),
        },
        secondary: {
            main: '#0F1116',
            dark: darken('#0F1116', 0.2),
            light: lighten('#0F1116', 0.2)
        },
        background: {
            default: '#0F1116',
            paper: '#F1F1F9',
        },
        text: {
            primary: '#F1F1F9',
            secondary: lighten('#0F1116', 0.3),
        },
        error: {
            main: '#FF4C4C',
        },
        success: {
            main: '#4CAF50',
        },
        info: {
            main: 'rgb(255, 140, 40)',
            dark: 'rgba(255, 140, 40, 0.2)'
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '20px',
                    padding: '10px 20px',
                    fontWeight: 600,
                    backgroundColor: '#0F1116',
                    color: '#F1F1F9',
                    '&:hover': {
                        backgroundColor: darken('#0F1116', 0.2),
                    },
                },
            },
        },
    },
});

export default theme;