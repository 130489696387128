import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Facebook, Instagram, YouTube } from '@mui/icons-material';
import { FaSoundcloud, FaTiktok } from 'react-icons/fa';
import { StyledFooterBox, SocialIconsContainer, StyledIconButton } from '../../styles/Navigation/Footer.style';
import { SocialMediaLinks, SocialMedias } from '../../enums/page_texts/homepage_texts';

const Footer: React.FC = () => {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomPosition = document.documentElement.scrollHeight;

    if (scrollPosition >= bottomPosition) {
      setIsAtBottom(true);
    } else {
      setIsAtBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleRedirect = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <StyledFooterBox isAtBottom={isAtBottom}>
      <SocialIconsContainer>
        <StyledIconButton onClick={() => handleRedirect(SocialMediaLinks.Facebook)}>
          <Facebook />
          {isAtBottom && <Typography sx={{ marginLeft: 1, color: 'white' }}>{SocialMedias.Facebook}</Typography>}
        </StyledIconButton>
        <StyledIconButton onClick={() => handleRedirect(SocialMediaLinks.SoundCloud)}>
          <FaSoundcloud />
          {isAtBottom && <Typography sx={{ marginLeft: 1, color: 'white' }}>{SocialMedias.SoundCloud}</Typography>}
        </StyledIconButton>
        <StyledIconButton onClick={() => handleRedirect(SocialMediaLinks.Instagram)}>
          <Instagram />
          {isAtBottom && <Typography sx={{ marginLeft: 1, color: 'white' }}>{SocialMedias.Instagram}</Typography>}
        </StyledIconButton>
        <StyledIconButton onClick={() => handleRedirect(SocialMediaLinks.YouTube)}>
          <YouTube />
          {isAtBottom && <Typography sx={{ marginLeft: 1, color: 'white' }}>{SocialMedias.YouTube}</Typography>}
        </StyledIconButton>
        <StyledIconButton onClick={() => handleRedirect(SocialMediaLinks.TikTok)}>
          <FaTiktok />
          {isAtBottom && <Typography sx={{ marginLeft: 1, color: 'white' }}>{SocialMedias.TikTok}</Typography>}
        </StyledIconButton>
      </SocialIconsContainer>
    </StyledFooterBox>
  );
};

export default Footer;
