import {styled} from '@mui/system'

export const BackgroundImageLeft = styled('img')({
    position: 'absolute',
    width: 'auto',
    height: '60%',
    left: '25%',
    transform: 'translateX(-50%)',
    border: '10px solid #ccc',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
    zIndex: 0
  });
  
  export const BackgroundImageRight = styled('img')({
    position: 'absolute',
    width: 'auto',
    height: '60%',
    right: '25%',
    transform: 'translateX(50%)',
    border: '10px solid #ccc',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
    zIndex: 0
  });