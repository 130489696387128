import React from 'react'
import { LogoSpinner, SpinnerContainer } from '../../styles/loadingscreen.style'

const LoadingSpinner = () => {
    return (
        <SpinnerContainer>
            <LogoSpinner src="/logos/mayday.svg" alt="Loading" />
        </SpinnerContainer>
    )
}

export default LoadingSpinner