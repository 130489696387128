import { styled } from '@mui/system';
import { Box, Divider, Paper, Typography } from '@mui/material';

export const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'align',
})<{ align?: 'left' | 'right' }>(({ theme, align }) => ({
    backgroundImage: 'url(/images/platines.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    minHeight: '80vh',
    display: 'flex',
    justifyContent: align === 'left' ? 'flex-start' : 'flex-end',
    alignItems: 'center',
    padding: '0 20px',
    maxHeight: '400px',
}));

export const StyledPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'align',
})<{ align?: 'left' | 'right' }>(({ theme, align }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 10,
    padding: 3,
    width: '80%',
    maxWidth: 800,
    textAlign: 'center',
    alignItems: 'center',
    color: '#AE29D5',
    boxShadow: `0px 0px 10px 2px rgba(174, 41, 213, 0.8)`,
    margin: align === 'left' ? '0 auto 0 0' : '0 0 0 auto',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
    width: '30%',
    margin: '20px auto',
    border: 'none',
    borderTop: '2px solid #AE29D5',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    fontWeight: 700,
    position: 'relative',
    color: '#AE29D5',
    textShadow: '0 0 10px rgba(174, 41, 213, 0.6)', // Blurry shadow
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        filter: 'blur(8px)',
        zIndex: -1,
    },
}));

export const StyledBigTitle = styled(Typography)(({ theme }) => ({
    marginLeft: '10%',
    fontSize: '4rem',
    fontWeight: 800,
    color: '#FFFFFF',
    textShadow: '0 0 10px rgba(174, 41, 213, 0.8)',
    zIndex: 1000,
    pointerEvents: 'none', // Ensure the title doesn’t interfere with interactivity
}));