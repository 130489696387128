import axios from 'axios';
import API_BASE_URL from '../config/apiConfig';
import { DescriptiveSectionTexts, GeneralTexts, HistorySectionTexts, HomeIntroTexts, ScrollingTexts, SocialMediaLinks, SocialMedias } from '../enums/page_texts/homepage_texts';
import { AgeOptions, BudgetOptions, ContactFormsTexts, EventTypes, FormSteps, MaterialOptions, MusicStyles, Step1FormQuestions, Step2FormQuestions, Step3FormQuestions, Step4FormQuestions, Step5FormQuestions, VenueTypes, VenueTypesForFestival } from '../enums/page_texts/contact_forms_texts';
import { ArtisPropositionTexts, FormulasPageGeneralTexts, FormulasPictures, PrivateEventsDescription, PrivateEventsTableCategories, PrivateEventsTableColumnTitles, PrivateEventsTableRows, PrivateEventsTableSubcategories, PublicEventsColumnTitles, PublicEventsDescription, PublicEventsFormulasTableTexts } from '../enums/page_texts/formulas_texts';
import { FooterColumn1Items, FooterColumn2Items, FooterColumn3Items, FooterColumn4Items, FooterMainTexts } from '../enums/footer_texts';
import { Card, EventsImage, EventsTypes, GalleryCard, PhotoOrReview } from '../interfaces/types';
import { HomeEventsSectionPictures, HomeHistorySectionPictures, MenuPictures, TopSectionsPictures } from '../enums/images';
import { PrivateEventsTexts } from '../enums/page_texts/private_events_texts';
import { PublicEventsTexts } from '../enums/page_texts/public_events_texts';



const loadMultiPageData = async (): Promise<void> => {
    await fetchHomepageGeneralTexts()
    await loadFooter()
    await fetchMenuPictures()
}

export const loadHomePageData = async (): Promise<void> => {
    await loadMultiPageData()
    await fetchHomepageIntroTexts()
    await fetchHomepageScrollingTexts()
    await fetchSocialMedias()
    await fetchSocialMediaLinks()
    await fetchHistorySectionTexts()
    await fetchDescriptiveSectionTexts()
    await fetchFormSteps()
    await fetchContactFormsTexts()
    await fetchEventTypes()
    await fetchVenueTypes()
    await fetchVenueTypesFestival()
    await fetchMaterialOptions()
    await fetchMusicStyles()
    await fetchAgeOptions()
    await fetchBudgetOptions()
    await fetchStep1FormQuestions()
    await fetchStep2FormQuestions()
    await fetchStep3FormQuestions()
    await fetchStep4FormQuestions()
    await fetchStep5FormQuestions()

    await fetchHomeEventsSectionPictures()
    await fetchHomeHistorySectionPictures()
}

export const loadContactFormData = async (): Promise<void> => {
    await loadMultiPageData()
    await fetchFormSteps()
    await fetchContactFormsTexts()
    await fetchEventTypes()
    await fetchVenueTypes()
    await fetchVenueTypesFestival()
    await fetchMaterialOptions()
    await fetchMusicStyles()
    await fetchAgeOptions()
    await fetchBudgetOptions()
    await fetchStep1FormQuestions()
    await fetchStep2FormQuestions()
    await fetchStep3FormQuestions()
    await fetchStep4FormQuestions()
    await fetchStep5FormQuestions()
}

export const loadFomulasPageData = async (): Promise<void> => {
    await loadMultiPageData()
    await fetchFormulasPictures()
    await fetchPrivateEventsDescription()
    await fetchPublicEventsDescription()
    await fetchFormulasPageGeneralTexts()
    await fetchPrivateEventsTableColumnTitles()
    await fetchPrivateEventsTableCategories()
    await fetchPrivateEventsTableSubcategories()
    await fetchPrivateEventsTableRow()
    await fetchPublicEventsColumnTitles()
    await fetchPublicEventsFormulasTable()
    await fetchArtisPropositionTexts()
}

export const loadPrivateEventsPageData = async (): Promise<void> => {
    await loadMultiPageData()
    await fetchPrivateEventsIntroTexts()
}

export const loadPublicEventsPageData = async (): Promise<void> => {
    await loadMultiPageData()
    await fetchPublicEventsIntroTexts()
}

export const loadFooter = async (): Promise<void> => {
    await fetchFooterMainTexts();
    await fetchFooterColumn1Items();
    await fetchFooterColumn2Items();
    await fetchFooterColumn3Items();
    await fetchFooterColumn4Items();
}

export const fetchMessage = async (): Promise<string> => {
    const response = await axios.get<{ message: string }>(API_BASE_URL + "hello/")
    return response.data.message
};

export const fetchTypesEvents = async () => {
    const response = await axios.get(`${API_BASE_URL}/types-events/`)
    return response.data
};

export const fetchHomepageGeneralTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}homepage-general-texts/`)
    const texts = response.data[0]

    GeneralTexts.Name = texts.name;
}

export const fetchHomepageIntroTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}homepage-intro-texts/`)
    const texts = response.data[0]

    HomeIntroTexts.DescriptiveText = texts.descriptive_text;
    HomeIntroTexts.ButtonText = texts.button_text;
    HomeIntroTexts.ModalText = texts.modal_text;
    HomeIntroTexts.CloseModal = texts.close_modal;
    HomeIntroTexts.AboutModal = texts.about_modal;
    TopSectionsPictures.HomePage = texts.homepage_picture.replace('media', 'images')
}

export const fetchHomepageScrollingTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}homepage-scrolling-texts/`)
    const texts = response.data[0]

    ScrollingTexts.Row1Text1 = texts.row1_text1;
    ScrollingTexts.Row1Text2 = texts.row1_text2;
    ScrollingTexts.Row1Text3 = texts.row1_text3;
    ScrollingTexts.Row2Text1 = texts.row2_text1;
    ScrollingTexts.Row2Text2 = texts.row2_text2;
    ScrollingTexts.Row2Text3 = texts.row2_text3;
}

export const fetchSocialMedias = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}social-medias/`)
    const texts = response.data[0]

    SocialMedias.Facebook = texts.facebook;
    SocialMedias.Instagram = texts.instagram;
    SocialMedias.SoundCloud = texts.soundCloud;
    SocialMedias.TikTok = texts.tiktok;
    SocialMedias.YouTube = texts.youtube;
}

export const fetchSocialMediaLinks = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}social-media-links/`)
    const texts = response.data[0]

    SocialMediaLinks.Facebook = texts.facebook;
    SocialMediaLinks.Instagram = texts.instagram;
    SocialMediaLinks.SoundCloud = texts.soundCloud;
    SocialMediaLinks.YouTube = texts.youtube;
    SocialMediaLinks.TikTok = texts.tiktok;
    SocialMediaLinks.Email = texts.email;
}

export const fetchHistorySectionTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}history-section-texts/`)
    const texts = response.data[0]

    HistorySectionTexts.Title = texts.title;
    HistorySectionTexts.Subtitle = texts.subtitle;
    HistorySectionTexts.Description = texts.description;
    HistorySectionTexts.ButtonText = texts.button_text;
}

export const fetchDescriptiveSectionTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}descriptive-section-texts/`)
    const texts = response.data[0]

    DescriptiveSectionTexts.Title = texts.title;
}

// FOOTER

export const fetchFooterMainTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}footer-main-texts/`);
    const texts = response.data[0];

    FooterMainTexts.LeftTitle = texts.left_title;
    FooterMainTexts.Column1 = texts.column1;
    FooterMainTexts.Column2 = texts.column2;
    FooterMainTexts.Column3 = texts.column3;
    FooterMainTexts.Column4 = texts.column4;
};

export const fetchFooterColumn1Items = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}footer-column1-items/`);
    const items = response.data[0];

    FooterColumn1Items.Item1 = items.item1;
    FooterColumn1Items.Item2 = items.item2;
    FooterColumn1Items.Item3 = items.item3;
};

export const fetchFooterColumn2Items = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}footer-column2-items/`);
    const items = response.data[0];

    FooterColumn2Items.Item1 = items.item1;
    FooterColumn2Items.Item2 = items.item2;
    FooterColumn2Items.Item3 = items.item3;
    FooterColumn2Items.Link1 = items.link1;
    FooterColumn2Items.Link2 = items.link2;
    FooterColumn2Items.Link3 = items.link3;
};

export const fetchFooterColumn3Items = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}footer-column3-items/`);
    const items = response.data[0];

    FooterColumn3Items.Item1 = items.item1;
    FooterColumn3Items.Item2 = items.item2;
    FooterColumn3Items.Item3 = items.item3;
    FooterColumn3Items.Link1 = items.link1;
    FooterColumn3Items.Link2 = items.link2;
    FooterColumn3Items.Link3 = items.link3;
};

export const fetchFooterColumn4Items = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}footer-column4-items/`);
    const items = response.data[0];

    FooterColumn4Items.Item1 = items.item1;
    FooterColumn4Items.Item2 = items.item2;
    FooterColumn4Items.Item3 = items.item3;
    FooterColumn4Items.Link1 = items.link1;
    FooterColumn4Items.Link2 = items.link2;
    FooterColumn4Items.Link3 = items.link3;
};


// PRIVATE EVENTS

export const fetchPrivateEventsIntroTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}private-events-intro-texts/`)
    const texts = response.data[0]

    PrivateEventsTexts.Title = texts.title
    PrivateEventsTexts.DescriptionText = texts.description_text
    PrivateEventsTexts.ButtonText = texts.button_text
    PrivateEventsTexts.ModalText = texts.modal_text
    PrivateEventsTexts.CloseModal = texts.close_modal
    PrivateEventsTexts.AboutModal = texts.about_modal
    TopSectionsPictures.PrivateEventsPage = texts.private_events_picture.replace('media', 'images')
}



// PUBLIC EVENTS

export const fetchPublicEventsIntroTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}public-events-intro-texts/`)
    const texts = response.data[0]

    PublicEventsTexts.Title = texts.title
    PublicEventsTexts.DescriptionText = texts.description_text
    PublicEventsTexts.ButtonText = texts.button_text
    PublicEventsTexts.ModalText = texts.modal_text
    PublicEventsTexts.CloseModal = texts.close_modal
    PublicEventsTexts.AboutModal = texts.about_modal
    TopSectionsPictures.PublicEventsPage = texts.public_events_picture.replace('media', 'images')
}


// CONTACT FORM

export const fetchContactFormsTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}contact-form-texts/`);
    const texts = response.data[0];

    ContactFormsTexts.Title = texts.title;
    ContactFormsTexts.DescriptionText = texts.description_text;
    ContactFormsTexts.ModalText = texts.modal_text;
    ContactFormsTexts.ButtonText = texts.button_text;
    ContactFormsTexts.AboutModal = texts.about_modal;
    ContactFormsTexts.CloseModal = texts.close_modal;
    ContactFormsTexts.OpenFormButton = texts.open_form_button;
    ContactFormsTexts.CloseFormDialog = texts.close_form_dialog;
    TopSectionsPictures.FormulasPage = texts.formulas_page_picture.replace('media', 'images')

};

export const fetchFormSteps = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}form-steps/`);
    const steps = response.data[0];

    FormSteps.Step1 = steps.step1;
    FormSteps.Step2 = steps.step2;
    FormSteps.Step3 = steps.step3;
    FormSteps.Step4 = steps.step4;
    FormSteps.Step5 = steps.step5;
    FormSteps.Step6 = steps.step6;
};

export const fetchEventTypes = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}event-types/`);
    const types = response.data[0];

    EventTypes.Private1 = types.private1;
    EventTypes.Private2 = types.private2;
    EventTypes.Private3 = types.private3;
    EventTypes.Public1 = types.public1;
    EventTypes.Public2 = types.public2;
    EventTypes.Other = types.other;
};

export const fetchVenueTypes = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}venue-types/`);
    const types = response.data[0];

    VenueTypes.Venue1 = types.venue1;
    VenueTypes.Venue2 = types.venue2;
    VenueTypes.Venue3 = types.venue3;
    VenueTypes.Venue4 = types.venue4;
    VenueTypes.Venue5 = types.venue5;
    VenueTypes.Venue6 = types.venue6;
    VenueTypes.Venue7 = types.venue7;
    VenueTypes.VenueOther = types.venueOther;
};

export const fetchVenueTypesFestival = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}venue-types-festival/`);
    const types = response.data[0];

    VenueTypesForFestival.Venue1 = types.venue1;
    VenueTypesForFestival.Venue2 = types.venue2;
    VenueTypesForFestival.Venue3 = types.venue3;
    VenueTypesForFestival.Venue4 = types.venue4;
    VenueTypesForFestival.VenueOther = types.venueOther;
};

export const fetchMaterialOptions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}material-options/`);
    const options = response.data[0];

    MaterialOptions.Material1 = options.material1;
    MaterialOptions.Material2 = options.material2;
    MaterialOptions.Material3 = options.material3;
    MaterialOptions.Material4 = options.material4;
    MaterialOptions.Material5 = options.material5;
    MaterialOptions.Material6 = options.material6;
    MaterialOptions.Material7 = options.material7;
    MaterialOptions.Material8 = options.material8;
    MaterialOptions.Material9 = options.material9;
    MaterialOptions.MaterialOther = options.material_other;
    MaterialOptions.MaterialAlreadyThere = options.material_already_there;
};

export const fetchMusicStyles = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}music-styles/`);
    const styles = response.data[0];

    MusicStyles.MusicStyle1 = styles.music_style1;
    MusicStyles.MusicStyle2 = styles.music_style2;
    MusicStyles.MusicStyle3 = styles.music_style3;
    MusicStyles.MusicStyle4 = styles.music_style4;
    MusicStyles.MusicStyle5 = styles.music_style5;
    MusicStyles.MusicStyleMix = styles.music_style_mix;
    MusicStyles.MusicStyleOther = styles.music_style_other;
};

export const fetchAgeOptions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}age-options/`);
    const options = response.data[0];

    AgeOptions.Option1 = options.option1;
    AgeOptions.Option2 = options.option2;
    AgeOptions.Option3 = options.option3;
    AgeOptions.Option4 = options.option4;
    AgeOptions.Option5 = options.option5;
    AgeOptions.Option6 = options.option6;
};

export const fetchBudgetOptions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}budget-options/`);
    const options = response.data[0];

    BudgetOptions.Option1 = options.option1;
    BudgetOptions.Option2 = options.option2;
    BudgetOptions.Option3 = options.option3;
    BudgetOptions.Option4 = options.option4;
    BudgetOptions.Option5 = options.option5;
    BudgetOptions.OptionIDK = options.optionIDK;
};

export const fetchStep1FormQuestions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}step1-questions/`);
    const questions = response.data[0];

    Step1FormQuestions.Question1 = questions.question1;
    Step1FormQuestions.Question2 = questions.question2;
    Step1FormQuestions.Question3 = questions.question3;
    Step1FormQuestions.Question4 = questions.question4;
};

export const fetchStep2FormQuestions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}step2-questions/`);
    const questions = response.data[0];

    Step2FormQuestions.Question1 = questions.question1;
    Step2FormQuestions.Question2 = questions.question2;
    Step2FormQuestions.Question3 = questions.question3;
    Step2FormQuestions.Question4 = questions.question4;
};

export const fetchStep3FormQuestions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}step3-questions/`);
    const questions = response.data[0];

    Step3FormQuestions.Question1 = questions.question1;
    Step3FormQuestions.Question2 = questions.question2;
    Step3FormQuestions.Question3 = questions.question3;
    Step3FormQuestions.Question4 = questions.question4;
    Step3FormQuestions.Question5 = questions.question5;
};

export const fetchStep4FormQuestions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}step4-questions/`);
    const questions = response.data[0];

    Step4FormQuestions.Question1 = questions.question1;
};

export const fetchStep5FormQuestions = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}step5-questions/`);
    const questions = response.data[0];

    Step5FormQuestions.Question1 = questions.question1;
    Step5FormQuestions.Question2 = questions.question2;
    Step5FormQuestions.Question3 = questions.question3;
    Step5FormQuestions.Question4 = questions.question4;
    Step5FormQuestions.Question5 = questions.question5;
};

// FORMULAS PAGE
export const fetchPrivateEventsDescription = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}private-events-description/`);
    const description = response.data[0];

    PrivateEventsDescription.Subtitle1 = description.subtitle1;
    PrivateEventsDescription.Subtitle2 = description.subtitle2;
    PrivateEventsDescription.Subtitle3 = description.subtitle3;
    PrivateEventsDescription.Text1 = description.text1;
    PrivateEventsDescription.Text2 = description.text2;
    PrivateEventsDescription.Text3 = description.text3;
}

export const fetchPublicEventsDescription = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}public-events-description/`);
    const description = response.data[0];

    PublicEventsDescription.Subtitle1 = description.subtitle1;
    PublicEventsDescription.Subtitle2 = description.subtitle2;
    PublicEventsDescription.Subtitle3 = description.subtitle3;
    PublicEventsDescription.Text1 = description.text1;
    PublicEventsDescription.Text2 = description.text2;
    PublicEventsDescription.Text3 = description.text3;
}


export const fetchFormulasPageGeneralTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}formulas-page-general-texts/`);
    const generalTexts = response.data[0];

    FormulasPageGeneralTexts.PrivateEventsName = generalTexts.private_events_name;
    FormulasPageGeneralTexts.PublicEventsName = generalTexts.public_events_name;
    FormulasPageGeneralTexts.PrivateFormulasTitle = generalTexts.private_formulas_title;
    FormulasPageGeneralTexts.PublicFormulasTitle = generalTexts.public_formulas_title;
    FormulasPageGeneralTexts.ExtraTitle = generalTexts.extra_title;
    FormulasPageGeneralTexts.ExplanationText = generalTexts.explanation_text;
}


export const fetchPrivateEventsTableColumnTitles = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}private-events-table-column-titles/`);
    const columnTitles = response.data[0];

    PrivateEventsTableColumnTitles.CategoryTitle = columnTitles.category_title;
    PrivateEventsTableColumnTitles.ItemTitle = columnTitles.item_title;
}


export const fetchPrivateEventsTableCategories = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}private-events-table-categories/`);
    const categories = response.data[0];

    PrivateEventsTableCategories.Category1 = categories.category1;
    PrivateEventsTableCategories.Category2 = categories.category2;
    PrivateEventsTableCategories.Category3 = categories.category3;
}


export const fetchPrivateEventsTableSubcategories = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}private-events-table-subcategories/`);
    const subcategories = response.data[0];

    PrivateEventsTableSubcategories.Subcategory1 = subcategories.subcategory1;
    PrivateEventsTableSubcategories.Subcategory2 = subcategories.subcategory2;
}


export const fetchPrivateEventsTableRow = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}private-events-table-rows/`);
    const rows = response.data;

    rows.forEach((row: any) => {
        // Make sure the category exists in the object
        if (PrivateEventsTableRows[row.category]) {
            PrivateEventsTableRows[row.category].data = row.data;
        } else {
            // If the category does not exist, initialize it
            PrivateEventsTableRows[row.category] = {
                data: row.data,
            };
        }
    });
}


export const fetchPublicEventsColumnTitles = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}public-events-column-titles/`);
    const columnTitles = response.data[0];

    PublicEventsColumnTitles.FormulaTitle = columnTitles.formula_title;
    PublicEventsColumnTitles.ContentTitle = columnTitles.content_title;
    PublicEventsColumnTitles.EventsTitle = columnTitles.events_title;
    PublicEventsColumnTitles.SelectionTitle = columnTitles.selection_title;
}

export const fetchPublicEventsFormulasTable = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}public-events-formulas-table/`);
    const formulasTable = response.data;

    PublicEventsFormulasTableTexts.length = 0;  // Clear the existing array
    formulasTable.forEach((formula: any) => {
        PublicEventsFormulasTableTexts.push({
            Name: formula.name,
            Description: formula.description,
            EventType: formula.event_type,
            IsSelected: formula.is_selected,
        });
    });
};


export const fetchArtisPropositionTexts = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}artis-proposition-texts/`);
    const propositionTexts = response.data[0];

    ArtisPropositionTexts.Question = propositionTexts.question;
    ArtisPropositionTexts.YesLabel = propositionTexts.yes_label;
    ArtisPropositionTexts.NoLabel = propositionTexts.no_label;
}

// FETCH AVEC DES IMAGES

export const fetchExtraCards = async (): Promise<Card[]> => {
    const response = await axios.get(`${API_BASE_URL}extra-cards/`);

    return response.data.map((data: any): Card => {
        const imageName = data.image.split('/').pop() || ''; // On garde le dernier segment après '/'
        const image = `/images/extra_cards/${imageName}`;

        return {
            name: data.name,
            image: image,
        };
    });
}

export const fetchHomeEventsSectionPictures = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}home-events-section-pictures/`);
    const pictures = response.data[0];

    HomeEventsSectionPictures.PrivateEventsPicture = pictures.private_events_picture.replace('media', 'images')
    HomeEventsSectionPictures.PublicEventsPicture = pictures.public_events_picture.replace('media', 'images')
}

export const fetchHomeHistorySectionPictures = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}home-history-section-pictures/`);
    const pictures = response.data[0];

    HomeHistorySectionPictures.MainPicture = pictures.main_picture.replace('media', 'images')
    HomeHistorySectionPictures.TopLeftPicture = pictures.top_left_picture.replace('media', 'images')
    HomeHistorySectionPictures.TopRightPicture = pictures.top_right_picture.replace('media', 'images')
    HomeHistorySectionPictures.BottomLeftPicture = pictures.bottom_left_picture.replace('media', 'images')
    HomeHistorySectionPictures.BottomRightPicture = pictures.bottom_right_picture.replace('media', 'images')
}

export const fetchFormulasPictures = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}formulas-pictures/`);
    const pictures = response.data[0];

    FormulasPictures.AnniversaireEssentielle = pictures.anniversaire_essentielle.replace('media', 'images')
    FormulasPictures.AnniversairePrestige = pictures.anniversaire_prestige.replace('media', 'images')
    FormulasPictures.MariageEssentielle = pictures.mariage_essentielle.replace('media', 'images')
    FormulasPictures.MariagePrestige = pictures.mariage_prestige.replace('media', 'images')
    FormulasPictures.EntrepriseEssentielle = pictures.entreprise_essentielle.replace('media', 'images')
    FormulasPictures.EntreprisePrestige = pictures.entreprise_prestige.replace('media', 'images')
}

export const fetchMenuPictures = async (): Promise<void> => {
    const response = await axios.get(`${API_BASE_URL}menu-pictures/`);
    const pictures = response.data[0];

    MenuPictures.Main = pictures.main.replace('media', 'images')
    MenuPictures.HomePicture = pictures.homepage.replace('media', 'images')
    MenuPictures.PrivateEventsPicture = pictures.private_events.replace('media', 'images')
    MenuPictures.PublicEventsPicture = pictures.public_events.replace('media', 'images')
    MenuPictures.FormulasPicture = pictures.formulas.replace('media', 'images')
    MenuPictures.FormPicture = pictures.form.replace('media', 'images')
}

export const fetchPhotosAndReviews = async () => {
    const response = await axios.get(`${API_BASE_URL}photos-or-reviews/`);
    const updatedItems = response.data.map((item: PhotoOrReview) => ({
        ...item,
        image: item.image?.replace('/media/', '/images/'),
    }));
    return updatedItems;
};

export const fetchGalleryCards = async () => {
    const response = await axios.get(`${API_BASE_URL}gallery-cards/`);
    const updatedCards = response.data.map((card: GalleryCard) => ({
        ...card,
        image: card.image.replace('/media/', '/images/'),
    }))

    return updatedCards
}

export const fetchPrivateEventsGallery = async () => {
    const response = await axios.get(`${API_BASE_URL}private-events-gallery/`)
    const modifiedData = response.data.map((event: EventsTypes) => ({
        ...event,
        images: event.images.map((image: EventsImage) => ({
            ...image,
            src: image.src.replace('/media/', '/images/')
        }))
    }));
    return modifiedData
}

export const fetchPublicEventsGallery = async () => {
    const response = await axios.get(`${API_BASE_URL}public-events-gallery/`)
    const modifiedData = response.data.map((event: EventsTypes) => ({
        ...event,
        images: event.images.map((image: EventsImage) => ({
            ...image,
            src: image.src.replace('/media/', '/images/')
        }))
    }));
    return modifiedData
}


// EMAILS

export const submitForm = async (formData: Record<string, any>): Promise<string> => {

    try {
        const response = await axios.post<{ message: string }>(
            `${API_BASE_URL}submit-form/`,
            formData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data.message;
    } catch (error) {
        console.error('Error submitting form:', error);
        throw new Error('Error submitting form');
    }
};