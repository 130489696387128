import { ThemeProvider } from '@emotion/react';
import MainRouter from './components/navigation/Routes';

import theme from './styles/theme';

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <MainRouter />
            </ThemeProvider>
        </div>
    );
}

export default App;