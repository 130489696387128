import React from 'react'
import ScrollingText from './ScrollingText'
import { SectionContainer } from '../../../styles/common.style'
import { BackgroundImageLeft, BackgroundImageRight } from '../../../styles/Homepage/BackgroundPictures.style'
import { HomeEventsSectionPictures } from '../../../enums/images'

const EventsSection: React.FC = () => {
  return (
    <SectionContainer>
        <BackgroundImageLeft src={HomeEventsSectionPictures.PrivateEventsPicture} alt="Private Events" />
        <BackgroundImageRight src={HomeEventsSectionPictures.PublicEventsPicture} alt="Public Events" />
      <ScrollingText />
    </SectionContainer>
  )
}

export default EventsSection