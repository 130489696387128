import { styled } from '@mui/system';
import { Card, Box, IconButton, Typography } from '@mui/material';

export const GalleryContainer = styled(Box)({
    gridColumn: 'span 6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '52%',
    margin: 'auto',
    height: 300,
});

export const StyledCard = styled(Card)({
    minWidth: 200,
    maxWidth: 200,
    maxHeight: 200,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1C1E24',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    margin: '0 10px',
});


export const TitleBox = styled(Box)({
    gridColumn: 'span 2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%'
  });

  export const GalleryScroll = styled(Box)(({ theme }) => ({
    display: 'flex',
    overflowX: 'auto',
    gap: theme.spacing(2),
    flex: 1,
    scrollBehavior: 'smooth',
    scrollSnapType: 'x mandatory',
    height: '100%',
    alignItems: 'center',
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  }));

  export const StyledArrowIconButton = styled(IconButton)({
    color: 'white',
  });
  
  export const StyledCardHeader = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: theme.spacing(1),
  }));