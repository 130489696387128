import Header from '../menu/Header'
import PageIntro from '../homepage/intro_section/IntroSection'
import { TopSectionsPictures } from '../../enums/images'
import { PrivateEventsTexts } from '../../enums/page_texts/private_events_texts'
import Footer from '../footer/Footer'
import PrivateEventsSection from './PrivateEventsSection'
import StaticFooter from '../footer/StaticFooter'
import { useEffect, useState } from 'react'
import { loadPrivateEventsPageData } from '../../services/api'
import LoadingSpinner from '../general/LoadingSpinner'

function PrivateEventsPage() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTexts = async () => {
      await loadPrivateEventsPageData()
      setLoading(false)
    }

    loadTexts()
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <Header />
      <PageIntro title={PrivateEventsTexts.Title}
        description={PrivateEventsTexts.DescriptionText}
        backgroundImage={TopSectionsPictures.PrivateEventsPage}
        modalText={PrivateEventsTexts.ModalText}
        aboutModal={PrivateEventsTexts.AboutModal}
        closeModal={PrivateEventsTexts.CloseModal}
        buttonText={PrivateEventsTexts.ButtonText} />
      <PrivateEventsSection />
      <StaticFooter />
      <Footer />
    </div>
  )
}

export default PrivateEventsPage