import React from 'react'
import { StepFormContainer, StyledQuestionText, StyledTextField } from '../../styles/Forms/StepsForm.style';
import { Step4FormQuestions, Step5FormQuestions } from '../../enums/page_texts/contact_forms_texts';

interface Step5FormProps {
    formData: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        tvaNumber?: string;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errors: {
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
        email?: string;
        tvaNumber?: string;
    };
}

const Step5Form: React.FC<Step5FormProps> = ({ formData, handleChange, errors }) => {
    return (
        <StepFormContainer>
            {/* Veuilez entre votre prénom. */}
            <StyledQuestionText>
                {Step5FormQuestions.Question1}
            </StyledQuestionText>
            <StyledTextField
                label="Prénom"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName}
                margin="normal"
            />

            {/* Veuilez entre votre nom. */}
            <StyledQuestionText>
                {Step5FormQuestions.Question2}
            </StyledQuestionText>
            <StyledTextField
                label="Nom"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName}
                margin="normal"
            />

            {/* Veuilez entre votre numéro de téléphone. */}
            <StyledQuestionText>
                {Step5FormQuestions.Question3}
            </StyledQuestionText>
            <StyledTextField
                label="Numéro de téléphone"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                fullWidth
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
                margin="normal"
            />

            {/* Veuilez entre votre email. */}
            <StyledQuestionText>
                {Step5FormQuestions.Question4}
            </StyledQuestionText>
            <StyledTextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
                margin="normal"
            />

            {/* Veuilez entre votre numéro de TVA. */}
            <StyledQuestionText>
                {Step5FormQuestions.Question5}
            </StyledQuestionText>
            <StyledTextField
                label="Numéro de TVA"
                name="tvaNumber"
                value={formData.tvaNumber}
                onChange={handleChange}
                fullWidth
                error={!!errors.tvaNumber}
                helperText={errors.tvaNumber}
                margin="normal"
            />
        </StepFormContainer>
    )
}

export default Step5Form