import { styled, keyframes } from '@mui/system';

// KEYFRAMES
const discordSpin = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
`;

// STYLED COMPONENTS
export const SpinnerContainer = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.secondary.main,
}));

export const LogoSpinner = styled('img')(() => ({
    width: '100px',
    height: '100px',
    animation: `${discordSpin} 2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97)`,
    borderRadius: '50%',
}));