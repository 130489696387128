import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { EventsTypes } from '../../interfaces/types'
import { CarouselWrapper, EventTitle, EventDescription, CarouselContainer, CarouselOverlay, CarouselImage, StyledIconButton } from '../../styles/EventsPages/EventsSection'

interface PublicEventsSectionProps {
  eventsType: EventsTypes;
  side: 'left' | 'right';
}

const PublicEventsPictureGallery: React.FC<PublicEventsSectionProps> = ({ eventsType, side }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === eventsType.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? eventsType.images.length - 1 : prevIndex - 1
    );
  };

  const currentImage = eventsType.images[currentImageIndex];

  return (
    <CarouselWrapper>
      <Box display="flex" flexDirection={side === 'left' ? 'row' : 'row-reverse'} alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <CarouselContainer>
          <CarouselImage src={currentImage.src} alt={`${eventsType.title} - ${currentImage.location}`} />
          <CarouselOverlay>
            <Typography variant="caption">
              {currentImage.date} - {currentImage.location}
            </Typography>
          </CarouselOverlay>

          {/* Left Arrow */}
          <StyledIconButton
            onClick={handlePrevImage}
            sx={{ left: '10px' }}
          >
            <ChevronLeft />
          </StyledIconButton>

          {/* Right Arrow */}
          <StyledIconButton
            onClick={handleNextImage}
            sx={{ right: '10px' }}
          >
            <ChevronRight />
          </StyledIconButton>

        </CarouselContainer>

        <Box ml={3} flex="1" display="flex" flexDirection="column">
          <EventTitle variant="h5">{eventsType.title}</EventTitle>
          <EventDescription variant="body1">{eventsType.description}</EventDescription>
        </Box>
      </Box>
    </CarouselWrapper>
  );
};

export default PublicEventsPictureGallery;
