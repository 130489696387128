// export enum ContactFormsTexts {
//     Title = 'Mes formules',
//     DescriptionText = 'Envoie un dm je cherche la femme de ma (nuit^^) vie',
//     ModalText = 'Je proposes différentes formules, adaptées à chaque type d\'événement où vous pourriez avoir besoin de ma présence. Je possède le matériel dont vous rêvez pour votre event!',
//     OpenFormButton = 'Demander un devis',
//     CloseFormDialog = 'Are you sure you want to close the form? (Changes wont be saved)',
// }

export const ContactFormsTexts: { Title: string, DescriptionText: string, ModalText: string, ButtonText: string, AboutModal: string, CloseModal: string, OpenFormButton: string, CloseFormDialog: string } = {
    Title: '',
    DescriptionText: '',
    ModalText: '',
    ButtonText: '',
    AboutModal: '',
    CloseModal: '',
    OpenFormButton: '',
    CloseFormDialog: '',
} as const;

// export enum FormSteps {
//     Step1 = 'Type d\'événement et matériel',
//     Step2 = 'Informations complémentaires',
//     Step3 = 'Votre événement',
//     Step4 = 'Extras',
//     Step5 = 'Vos coordonnées',
//     Step6 = 'Validation',
// }

export const FormSteps: { Step1: string, Step2: string, Step3: string, Step4: string, Step5: string, Step6: string, } = {
    Step1: '',
    Step2: '',
    Step3: '',
    Step4: '',
    Step5: '',
    Step6: '',
} as const;

// export enum EventTypes {
//     Private1 = 'Mariage',
//     Private2 = 'Anniversaire',
//     Private3 = 'Evénement d\'entreprise',
//     Public1 = 'Bar/Club',
//     Public2 = 'Festival/Bal de village',
//     Other = 'Autre'
// }

export const EventTypes: { Private1: string, Private2: string, Private3: string, Public1: string, Public2: string, Other: string, } = {
    Private1: '',
    Private2: '',
    Private3: '',
    Public1: '',
    Public2: '',
    Other: '',
} as const;

// export enum VenueTypes {
//     Venue1 = 'Salle des fêtes',
//     Venue2 = 'Salle de concerts',
//     Venue3 = 'Centre de conférences',
//     Venue4 = 'Bureau',
//     Venue5 = 'Ecole',
//     Venue6 = 'Maison',
//     Venue7 = 'Extérieur',
//     VenueOther = 'Autre',
// }

export const VenueTypes: { Venue1: string, Venue2: string, Venue3: string, Venue4: string, Venue5: string, Venue6: string, Venue7: string, VenueOther: string } = {
    Venue1: '',
    Venue2: '',
    Venue3: '',
    Venue4: '',
    Venue5: '',
    Venue6: '',
    Venue7: '',
    VenueOther: '',
} as const;

// export enum VenueTypesForFestival {
//     Venue1 = 'Salle des fêtes',
//     Venue2 = 'Salle de concerts',
//     Venue3 = 'Ecole',
//     Venue4 = 'Extérieur',
//     VenueOther = 'Autre',
// }

export const VenueTypesForFestival: { Venue1: string, Venue2: string, Venue3: string, Venue4: string, VenueOther: string } = {
    Venue1: '',
    Venue2: '',
    Venue3: '',
    Venue4: '',
    VenueOther: '',
} as const;

// export enum MaterialOptions {
//     Material1 = 'Sonorisation',
//     Material2 = 'Eclairages',
//     Material3 = 'Service de karaoké',
//     Material4 = 'Machine à fumée légère',
//     Material5 = 'Projecteur & toile de projection',
//     Material6 = 'Machine à étincelles froides',
//     Material7 = 'Machine à fumée lourde',
//     Material8 = 'Spots architecturaux',
//     Material9 = 'Je ne sais pas',
//     MaterialOther = 'Autre',
//     MaterialAlreadyThere = 'Tout est déjà sur place',
// }

export const MaterialOptions: { Material1: string, Material2: string, Material3: string, Material4: string, Material5: string, Material6: string, Material7: string, Material8: string, Material9: string, MaterialOther: string, MaterialAlreadyThere: string } = {
    Material1: '',
    Material2: '',
    Material3: '',
    Material4: '',
    Material5: '',
    Material6: '',
    Material7: '',
    Material8: '',
    Material9: '',
    MaterialOther: '',
    MaterialAlreadyThere: ''
} as const;

// export enum MusicStyles {
//     MusicStyle1 = 'House (Deep, Lounge, Tech)',
//     MusicStyle2 = 'Pop',
//     MusicStyle3 = 'Rock',
//     MusicStyle4 = 'Hip-hop/rap',
//     MusicStyle5 = 'Techno, DnB, hardstyle',
//     MusicStyleMix = 'Un peu de tout',
//     MusicStyleOther = 'Autre',
// }

export const MusicStyles: { MusicStyle1: string, MusicStyle2: string, MusicStyle3: string, MusicStyle4: string, MusicStyle5: string, MusicStyleMix: string, MusicStyleOther: string } = {
    MusicStyle1: '',
    MusicStyle2: '',
    MusicStyle3: '',
    MusicStyle4: '',
    MusicStyle5: '',
    MusicStyleMix: '',
    MusicStyleOther: ''
} as const;

// export enum AgeOptions {
//     Option1 = '- de 12 ans',
//     Option2 = '13-18 ans',
//     Option3 = '19-30 ans',
//     Option4 = '31-50 ans',
//     Option5 = '51-65 ans',
//     Option6 = '+ de 65 ans',
// }

export const AgeOptions: { Option1: string, Option2: string, Option3: string, Option4: string, Option5: string, Option6: string } = {
    Option1: '',
    Option2: '',
    Option3: '',
    Option4: '',
    Option5: '',
    Option6: ''
} as const;

// export enum BudgetOptions {
//     Option1 = '- de 400€',
//     Option2 = 'entre 400 et 800€',
//     Option3 = 'entre 600 et 800€',
//     Option4 = 'entre 800 et 1000€',
//     Option5 = '+ de 1000€',
//     OptionIDK = 'Je ne sais pas',
// }

export const BudgetOptions: { Option1: string, Option2: string, Option3: string, Option4: string, Option5: string, OptionIDK: string } = {
    Option1: '',
    Option2: '',
    Option3: '',
    Option4: '',
    Option5: '',
    OptionIDK: ''
} as const;

// export enum Step1FormQuestions {
//     Question1 = 'De quel type d\'événement s\'agit-il?',
//     Question2 = 'Le matériel est-il déjà sur place?',
//     Question3 = 'De quels matériels avez-vous besoin?',
//     Question4 = 'Où se déroule votre événement?',
// }

export const Step1FormQuestions: { Question1: string, Question2: string, Question3: string, Question4: string } = {
    Question1: '',
    Question2: '',
    Question3: '',
    Question4: ''
} as const;

// export enum Step2FormQuestions {
//     Question1 = 'Style de musique souhaitée?',
//     Question2 = 'Quel âge ont les invités?',
//     Question3 = 'Quel est votre budget approximatif?',
//     Question4 = 'Quel est le nombre prévu d\'invités?',
// }

export const Step2FormQuestions: { Question1: string, Question2: string, Question3: string, Question4: string } = {
    Question1: '',
    Question2: '',
    Question3: '',
    Question4: ''
} as const;

// export enum Step3FormQuestions {
//     Question1 = 'Quelle est l\'adresse de l\'événement?',
//     Question2 = 'Quand aura lieu l\'événement?',
//     Question3 = 'La date est-elle flexible?',
//     Question4 = 'Horaire de prestation souhaité',
//     Question5 = 'Information supplémentaire à savoir concernant votre événement? (facultatif)',
// }

export const Step3FormQuestions: { Question1: string, Question2: string, Question3: string, Question4: string, Question5: string } = {
    Question1: '',
    Question2: '',
    Question3: '',
    Question4: '',
    Question5: ''
} as const;

// export enum Step4FormQuestions {
//     Question1 = 'Je suis également administrateur de la société ARTIS Events, grâce à laquelle nous proposons du matériel événementiel et HoReCa à la location à prix concurrentiels, souhaitez-vous recevoir notre catalogue en même temps que le devis?'
// }

export const Step4FormQuestions: { Question1: string } = {
    Question1: ''
} as const;

// export enum Step5FormQuestions {
//     Question1 = 'Veuilez entre votre prénom.',
//     Question2 = 'Veuilez entre votre nom de famille.',
//     Question3 = 'Veuilez entre votre numéro de téléphone.',
//     Question4 = 'Veuilez entre votre adresse email.',
//     Question5 = 'Veuilez entre votre numéro de TVA (facultatif).',
// }

export const Step5FormQuestions: { Question1: string, Question2: string, Question3: string, Question4: string, Question5: string } = {
    Question1: '',
    Question2: '',
    Question3: '',
    Question4: '',
    Question5: ''
} as const;