
import { Formula, PrivateEventsTableRow } from "../../interfaces/types";

export const FormulasPageGeneralTexts: {PrivateEventsName: string, PublicEventsName: string, PrivateFormulasTitle: string, PublicFormulasTitle: string, ExtraTitle: string, ExplanationText: string,} = {
    PrivateEventsName: '',
    PublicEventsName: '',
    PrivateFormulasTitle: '',
    PublicFormulasTitle: '',
    ExtraTitle: '',
    ExplanationText: '',
} as const

export const PrivateEventsTableColumnTitles: { CategoryTitle: string, ItemTitle: string,} = {
    CategoryTitle: '',
    ItemTitle: '',
} as const

export const PrivateEventsTableCategories: { Category1: string, Category2: string, Category3 : string} = {
    Category1: '',
    Category2: '',
    Category3: '',
} as const

export const PrivateEventsTableSubcategories: { Subcategory1: string, Subcategory2: string} = {
    Subcategory1: '',
    Subcategory2: '',
} as const


export const PrivateEventsTableRows: PrivateEventsTableRow = {
    Sonorisation: {
        data: [
        ],
    },
    Éclairages: {
        data: [
        ],
    },
    Prestation: {
        data: [
        ],
    },
}

export const PublicEventsColumnTitles: { FormulaTitle: string, ContentTitle: string, EventsTitle: string, SelectionTitle: string} = {
    FormulaTitle: '',
    ContentTitle: '',
    EventsTitle: '',
    SelectionTitle: '',
} as const;



export const PublicEventsFormulasTableTexts: Formula[] = [
    {
        Name: 'Essentielle',
        Description: [
            'DJ + animation micro',
            'Utilisation du matériel sur place',
        ],
        EventType: 'Festivals, bals, événements équipés.',
        IsSelected: false,
    },
    {
        Name: 'Confort',
        Description: [
            'DJ + animation micro',
            'Ajout de matériel son/lumière si nécessaire (ex. enceintes, micros, light show basique)',
        ],
        EventType: 'Soirées associatives, scènes de taille moyenne.',
        IsSelected: false,
    },
    {
        Name: 'Prestige',
        Description: [
            'DJ + animation micro',
            'Installation complète (sonorisation et éclairage professionnel)',
        ],
        EventType: 'Grands événements en plein air ou en salle.',
        IsSelected: false,
    },
];


export const ArtisPropositionTexts: { Question: string, YesLabel: string, NoLabel: string} = {
    Question: '',
    YesLabel: '',
    NoLabel: '',
} as const

export const PrivateEventsDescription: {Subtitle1: string, Subtitle2: string, Subtitle3: string, Text1: string, Text2: string, Text3: string,} = {
    Subtitle1: '',
    Subtitle2: '',
    Subtitle3: '',
    Text1: '',
    Text2: '',
    Text3: '',
} as const

export const PublicEventsDescription: {Subtitle1: string, Subtitle2: string, Subtitle3: string, Text1: string, Text2: string, Text3: string,} = {
    Subtitle1: '',
    Subtitle2: '',
    Subtitle3: '',
    Text1: '',
    Text2: '',
    Text3: '',
} as const

export const FormulasPictures: {AnniversaireEssentielle: string, AnniversairePrestige: string, MariageEssentielle: string, MariagePrestige: string, EntrepriseEssentielle: string, EntreprisePrestige: string} = {
    AnniversaireEssentielle: '',
    AnniversairePrestige: '',
    MariageEssentielle: '',
    MariagePrestige: '',
    EntrepriseEssentielle: '',
    EntreprisePrestige: '',
} as const