import React, { useRef } from 'react';
import { Typography, CardMedia } from '@mui/material';
import { TitleBox, GalleryContainer, GalleryScroll, StyledCard, StyledCardHeader, StyledArrowIconButton } from '../../styles/Galleries/CardGallery';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Card } from '../../interfaces/types';

interface RollingGalleryProps {
    title: string;
    cards: Card[];
}

const RollingGallery: React.FC<RollingGalleryProps> = ({ title, cards }) => {
    const galleryRef = useRef<HTMLDivElement>(null);

    const scrollGallery = (direction: 'left' | 'right') => {
        const gallery = galleryRef.current;
        if (!gallery) return;

        const scrollAmount = direction === 'left' ? -200 : 200;

        gallery.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    };

    return (
        <>
            <TitleBox>
                <Typography variant="h2" color="primary">
                    {title}
                </Typography>
            </TitleBox>
            <GalleryContainer>

                <StyledArrowIconButton onClick={() => scrollGallery('left')} aria-label="Scroll left">
                    <ArrowBackIos />
                </StyledArrowIconButton>

                <GalleryScroll ref={galleryRef}>
                    {cards.map((card, index) => (
                        <StyledCard key={index}>
                            <StyledCardHeader variant="subtitle2">{card.name}</StyledCardHeader>
                            <CardMedia component="img" src={card.image} alt={card.name} />
                        </StyledCard>
                    ))}
                </GalleryScroll>

                <StyledArrowIconButton onClick={() => scrollGallery('right')} aria-label="Scroll right">
                    <ArrowForwardIos />
                </StyledArrowIconButton>

            </GalleryContainer>
        </>
    );
};

export default RollingGallery;
